@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.page-contact-offices {
  position: relative;
  margin-bottom: -1px;
  z-index: 1;
}
.page-contact-offices .footer__item {
  color: #c9baa1;
  margin-right: auto;
  margin-top: 20px;
  margin-left: auto;
}

.poff__slider {
  display: none;
  margin-bottom: 30px;
}
.poff__slider.slick-initialized {
  display: block;
}
.poff__item {
  position: relative;
}
.poff__content {
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 105px;
  width: 100%;
  color: #FFFFFF;
  z-index: 1;
}
.poff__content h2 {
  font-size: 68px;
  line-height: 1.125;
  margin-bottom: 25px;
}
.poff__content h2 span {
  display: block;
  font-weight: 900;
  line-height: 1;
  margin-top: 7px;
}
.poff__content p {
  font-size: 13px;
  line-height: 1.3846153846;
}
.poff__boxes {
  margin-bottom: 21px;
}
.poff__inner {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  border: 1.5px solid #c9baa1;
  padding: 15px 20px;
}
.poff__icon {
  max-width: 100%;
  flex-basis: 100%;
  display: block;
  font-size: 67px;
  color: #c9baa1;
  margin-bottom: 12px;
}
.poff__maintitle {
  font-size: 20px;
  font-weight: 900;
  color: #c9baa1;
  margin-bottom: 14px;
}
.poff__title {
  max-width: 100%;
  flex-basis: 100%;
  font-size: 20px;
  color: #c9baa1;
  line-height: 1;
  margin-bottom: 25px;
}
.poff__desc {
  max-width: 100%;
  flex-basis: 100%;
}
.poff__desc * {
  color: #2D2F30;
  line-height: 1.2;
}
.poff__arrow {
  max-width: 100%;
  flex-basis: 100%;
  align-self: flex-end;
  font-size: 41px;
  color: #c9baa1;
}
.poff__box.active .poff__inner {
  background-color: #c9baa1;
}
.poff__box.active * {
  color: #FFFFFF;
}
.poff__box-full {
  display: none;
  padding-top: 30px;
  padding-bottom: 30px;
}
.poff__box-full-inner {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
}
.poff__box-full-left, .poff__box-full-right {
  max-width: 50%;
  flex-basis: 50%;
  padding-right: 15px;
  padding-left: 15px;
}
.poff__box-full-left img, .poff__box-full-right img {
  display: table;
  max-width: 100%;
  height: auto !important;
  float: none !important;
  margin-right: auto !important;
  margin-left: auto !important;
}
.poff__box-full ul {
  list-style-position: outside;
  list-style-type: disc;
  padding-left: 15px;
}
.poff__box-full ul li::marker {
  color: #c9baa1;
}
.poff__box-full.active {
  display: flex;
}

.poff__gallery {
  margin-bottom: 23px;
}
.poff__gallery .row {
  position: relative;
}
.poff__gallery-item {
  position: relative;
}
.poff__gallery-title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding-bottom: 17px;
}
.poff__gallery-title p {
  position: relative;
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
  z-index: 1;
}
.poff__gallery-title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100px;
  background: rgb(201, 255, 255);
  background: linear-gradient(180deg, rgba(201, 255, 255, 0) 0, rgba(201, 186, 161, 0.67) 100%);
}
.poff__gallery-arrow {
  max-width: 77px;
  flex-basis: 77px;
  align-self: center;
}
.poff__gallery-arrow button {
  cursor: pointer;
  font-size: 40px;
  color: #c9baa1;
}
.poff__gallery-arrow-prev {
  transform: rotate(90deg);
}
.poff__gallery-arrow-next {
  transform: rotate(-90deg);
}
.poff__gallery-arrow-left {
  left: 35px;
}
.poff__gallery-arrow-right {
  text-align: right;
  right: 35px;
}
.poff__gallery-slider {
  display: none;
  max-width: calc(100% - 154px);
  flex-basis: calc(100% - 154px);
}
.poff__gallery-slider.slick-initialized {
  display: block;
}
.poff__gallery-slider .slick-arrow {
  display: none !important;
  font-size: 0;
  text-indent: -9999px;
}
.poff__gallery-slider .slick-slide .poff__gallery-title {
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.poff__gallery-cover {
  position: absolute;
  top: 0;
  width: 447px;
  height: 100%;
  background-color: rgba(201, 186, 161, 0.67);
  z-index: 100;
}
.poff__gallery-cover-left {
  left: 15px;
}
.poff__gallery-cover-right {
  right: 15px;
}

.poff__contact-text {
  font-size: 20px;
  color: #c9baa1;
  font-weight: 800;
  margin-top: 25px;
  margin-bottom: 18px;
}

@media screen and (max-width: 1440px) {
  .poff__content {
    top: 90px;
  }
  .poff__content h2 {
    font-size: 50px;
  }
  .poff__desc * br {
    display: none;
  }
  .poff__gallery-slider {
    max-width: calc(100% - 90px);
    flex-basis: calc(100% - 90px);
  }
  .poff__gallery-item img {
    padding: 0 10px;
  }
  .poff__gallery-arrow {
    max-width: 45px;
    flex-basis: 45px;
  }
}
@media screen and (max-width: 1199px) {
  .poff__content {
    top: 70px;
  }
  .poff__box {
    margin-bottom: 30px;
  }
  .poff__box[data-target=poff__box-1] {
    order: 1;
  }
  .poff__box[data-target=poff__box-2] {
    order: 3;
  }
  .poff__box[data-target=poff__box-3] {
    order: 5;
  }
  .poff__box-full#poff__box-1 {
    order: 2;
  }
  .poff__box-full#poff__box-2 {
    order: 4;
  }
  .poff__box-full#poff__box-3 {
    order: 6;
  }
  .poff__box-full ul br {
    display: none;
  }
  .poff__icon {
    font-size: 50px;
  }
  .poff__desc {
    margin-bottom: 20px;
  }
  .poff__arrow {
    font-size: 30px;
  }
  .poff__gallery-arrow button {
    font-size: 30px;
  }
  .poff__gallery-cover {
    width: 310px;
  }
  .poff__gallery-item img {
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .poff__item img {
    height: 350px;
    object-position: center;
    object-fit: cover;
  }
  .poff__content {
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0 30px;
  }
  .poff__content h2 {
    font-size: 40px;
  }
  .poff__content p br {
    display: none;
  }
  .poff__box-full {
    padding-top: 0;
  }
  .poff__box-full-inner {
    flex-wrap: wrap;
  }
  .poff__box-full-left, .poff__box-full-right {
    max-width: 100%;
    flex-basis: 100%;
  }
  .poff__box-full-right img {
    margin-top: 30px;
  }
  .poff__gallery-title:before {
    height: 150px;
  }
  .poff__gallery-cover {
    display: none;
  }
  .poff__gallery .slick-slider .slick-active img {
    filter: unset;
  }
}
@media screen and (max-width: 768px) {
  .poff__gallery .slick-slider img {
    width: 100%;
  }
  .page-contact-offices .form-submit {
    margin-right: auto;
  }
}
@media screen and (max-width: 575px) {
  .poff__item img {
    height: 350px;
  }
  .poff__content {
    padding: 0 40px;
  }
  .poff__content h2 {
    font-size: 30px;
  }
  .poff__content p br {
    display: none;
  }
  .poff__box {
    margin-bottom: 20px;
  }
  .poff__icon {
    font-size: 40px;
  }
  .poff__title {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .poff__arrow {
    font-size: 25px;
  }
  .poff__gallery-arrow button {
    font-size: 25px;
  }
  .poff__gallery-title p {
    font-size: 18px;
  }
}