@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.page-thematic {
  text-align: center;
}
.page-thematic .a-center {
  align-items: center;
}
.page-thematic .thematic {
  padding-top: 50px;
}
.page-thematic .thematic-logo {
  padding: 50px 0;
}
.page-thematic .thematic-txt-1 {
  font-size: 42px;
  font-weight: 700;
  color: #e97142;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-bottom: 50px;
}
.page-thematic .thematic-txt-2 {
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 50px;
}
.page-thematic .thematic-txt-3 {
  font-size: 20px;
  padding-bottom: 40px;
}
.page-thematic .thematic-txt-4 {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 40px;
}
.page-thematic .thematic-txt-5 {
  font-size: 20px;
  text-align: left;
  padding-bottom: 30px;
}
.page-thematic .thematic-txt-6 {
  font-size: 20px;
  line-height: 1.7;
  text-transform: uppercase;
  padding-bottom: 50px;
}
.page-thematic .thematic-txt-6 span {
  color: #e97142;
}
.page-thematic .thematic-txt-7 {
  font-size: 28px;
  color: #e97142;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 30px;
}
.page-thematic .thematic-txt-8 {
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 50px;
}
.page-thematic .thematic-ico {
  width: 108px;
  height: auto;
  margin-bottom: 30px;
}

@media screen and (max-width: 1199px) {
  .page-thematic .col-lg-4 {
    max-width: 33.33333333%;
    flex-basis: 33.33333333%;
  }
}
@media screen and (max-width: 767px) {
  .page-thematic .thematic {
    padding-top: 30px;
  }
  .page-thematic .thematic-logo {
    padding: 30px 0;
  }
  .page-thematic .thematic-logo img {
    width: 150px;
  }
  .page-thematic .thematic-txt-1 {
    font-size: 20px;
    padding-bottom: 30px;
  }
  .page-thematic .thematic-txt-2 {
    font-size: 18px;
    padding-bottom: 30px;
  }
  .page-thematic .thematic-txt-3 {
    font-size: 16px;
    padding-bottom: 30px;
  }
  .page-thematic .thematic-txt-4 {
    font-size: 16px;
    padding-bottom: 30px;
  }
  .page-thematic .thematic-txt-5 {
    font-size: 16px;
    text-align: center;
    padding-bottom: 30px;
  }
  .page-thematic .thematic-txt-6 {
    font-size: 14px;
    line-height: 1.7;
    text-transform: uppercase;
    padding-bottom: 30px;
  }
  .page-thematic .thematic-txt-6 span {
    color: #e97142;
  }
  .page-thematic .thematic-txt-7 {
    font-size: 18px;
    padding-bottom: 30px;
  }
  .page-thematic .thematic-txt-8 {
    font-size: 18px;
    padding-bottom: 30px;
  }
  .page-thematic .thematic-ico {
    width: 80px;
    height: auto;
    margin-bottom: 30px;
  }
}