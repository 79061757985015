@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.page__map .pshop__hide {
  display: none;
}
.page__map .pshop__left {
  height: unset;
}
.page__map .pshop__right .btn {
  width: 100%;
  padding: 13.5px 10px;
  margin-top: 50px;
}
.page__map .slick-dots li:not(:last-child) button {
  margin-bottom: 18px;
}
.page__map .slick-dots li button {
  width: 31px;
  height: 31px;
  font-size: 15px;
  color: #C9BBA1;
  text-indent: 0;
  border: 2px solid #C9BBA1;
}
.page__map .slick-dots li button:before {
  display: none;
}
.page__map .slick-dots li.slick-active button {
  color: #1D1D1B;
  border-color: #1D1D1B;
}

.tc-map {
  padding-bottom: 50px;
}
.tc-map .tc-right .tc-logo {
  text-align: center;
}
.tc-map .tc-right .tc-logo img {
  max-width: 100%;
  height: auto;
}
.tc-map .tc-right .tc-opening {
  padding-top: 0;
}
.tc-map .tc-right .tc-contact {
  margin-bottom: 16px;
}
.tc-map .tc-right .tc-buttons {
  bottom: 50px;
}
.tc-map .tc-right .inner {
  padding-bottom: 50px;
}
.tc-map .tc-right .inner.has-buttons {
  padding-bottom: 140px;
}
.tc-map .tc-right .inner h2 {
  text-align: center;
  text-transform: uppercase;
}
.tc-map .tc-right .inner .btn-black {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.tc-map .tc-slider {
  background-color: #FFFFFF;
  overflow: hidden;
}
.tc-map .tc-slider-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 0 0 60px;
}
.tc-map .tc-slider-item svg {
  display: block;
  width: 100%;
  height: 100%;
}
.tc-map .tc-slider .slick-dots {
  left: 20px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 25px;
  padding: 15px 3px;
}
.tc-map .tc-slider .slick-dots button:before {
  color: rgba(0, 0, 0, 0.5);
}
.tc-map .tc-slider .slick-active button {
  border-color: rgba(0, 0, 0, 0.5);
}
.tc-map .tc-slider .slick-active button:before {
  color: rgba(0, 0, 0, 0.5);
}

.map-tooltip {
  pointer-events: none;
  position: absolute;
  max-width: 160px;
  text-align: center;
  background-color: #FFFFFF;
  box-shadow: rgba(201, 186, 161, 0.2) 0 7px 29px 0;
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  -moz-transition: opacity 300ms linear;
  -o-transition: opacity 300ms linear;
  -webkit-transition: opacity 300ms linear;
  transition: opacity 300ms linear;
  transition-delay: 200ms;
  opacity: 0;
  padding: 7px 16px;
  margin-top: 32px;
  z-index: 2;
}
.map-floor {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
  font-weight: 300;
  color: #FFFFFF;
  background-color: #000000;
  z-index: 2;
  padding: 0 5px;
}

.map-tooltip.visible {
  opacity: 1;
}

#background .st0,
#background .cls-4,
#background rect {
  fill: #FFFFFF !important;
  opacity: 1 !important;
}

.floor-container g {
  cursor: pointer;
  fill: #c9baa1;
}
.floor-container g.highlighted {
  fill: #1D1D1B !important;
}
.floor-container g.active, .floor-container g.selected {
  fill: #1D1D1B !important;
}
.floor-container g text {
  font-size: 0;
  opacity: 0;
  visibility: hidden;
}
.floor-container g:hover {
  fill: #1D1D1B !important;
}

@media screen and (max-width: 1199px) {
  .page__map .pshop__left {
    padding-right: 15px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  .tc-map .tc-slider-item {
    padding: 0;
  }
  .tc-map .tc-slider .slick-list {
    margin-top: 30px;
  }
  .tc-map .tc-slider .slick-dots {
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    transform: none;
    padding-top: 0;
    padding-bottom: 0;
  }
  .tc-map .tc-slider .slick-dots li {
    margin-right: 10px;
    margin-left: 10px;
  }
  .tc-map .tc-slider .slick-dots li:not(:last-child) button {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 574px) {
  .tc-shoppage .slick-dots li {
    height: 24px;
  }
  .tc-shoppage .slick-dots button {
    width: 24px;
    height: 24px;
    -ms-transform: translateY(-4px);
    -webkit-transform: translateY(-4px);
    -moz-transform: translateY(-4px);
    -o-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}