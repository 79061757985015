@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.mompark-b2b-login {
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.mompark-b2b-login .row {
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.mompark-b2b-login .mompark-login {
  max-width: 480px;
  background-color: #FFFFFF;
  padding: 40px 15px 30px;
}
.mompark-b2b-login .mompark-login-logo {
  display: table;
  margin-right: auto;
  margin-left: auto;
}
.mompark-b2b-login .mompark-login .tc-title {
  margin-bottom: 8px;
}
.mompark-b2b-login .mompark-login .tc-title:before {
  height: 6px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAAAAAA6fptVAAAACklEQVQI12P4CAAA8wDyQue8RAAAAABJRU5ErkJggg==");
}
.mompark-b2b-login .mompark-login .tc-title span {
  background-color: #FFFFFF;
  padding: 40px;
}
.mompark-b2b-login .mompark-login p {
  text-align: center;
  margin-bottom: 16px;
}
.mompark-b2b-login .mompark-login input::-webkit-input-placeholder,
.mompark-b2b-login .mompark-login textarea::-webkit-input-placeholder {
  font-style: normal;
  color: #A7A7A7;
  opacity: 1;
  -moz-transition: opacity 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
}
.mompark-b2b-login .mompark-login input::-moz-placeholder,
.mompark-b2b-login .mompark-login textarea::-moz-placeholder {
  font-style: normal;
  color: #A7A7A7;
  opacity: 1;
  -moz-transition: opacity 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
}
.mompark-b2b-login .mompark-login input:-ms-input-placeholder,
.mompark-b2b-login .mompark-login textarea:-ms-input-placeholder {
  font-style: normal;
  color: #A7A7A7;
  opacity: 1;
  -moz-transition: opacity 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
}
.mompark-b2b-login .mompark-login .form-control {
  background-color: #EEF1F2;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: auto;
}
.mompark-b2b-login .mompark-login .b2b_user {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAkFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD////Dn64xAAAAL3RSTlMAASBFXGgsbYB+WQVWbDAHZUEDLghLcQJmf1gpMT8vJQZweUQQZA9yeh1aexoNJAEc8qgAAAABYktHRC8j1CARAAAACXBIWXMAAAsSAAALEgHS3X78AAAA4UlEQVQY03VRyRaCMAwMhcqALFY2d1BcUND//zxpCupB5tLJvL5JJiFiWMJ2pHRsYdEPZi486fvSgzv7iPMAYRRrFkchgvmgLqCW45elwsLoAZJfwwQB+0LpJ83yPEs1U+j9LTfUDsVqvdmsV4X2CV2LBCL9ZYsd0Q5bzSMIsj2eYY/+ibHneTybHMmNDiiJShy4kA5Jn1l1PNX16Vhx4ctRPl/Q43IeZWNyvTXqflfN7TqYmJaPttN11z6GljzgE7WJWONpBuQ4r54wBF4mzhj+Cw4/taqpxU6cYepof078BuorDf0iXJdIAAAAAElFTkSuQmCC");
}
.mompark-b2b-login .mompark-login .b2b_pass {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAWCAMAAAD+dOxOAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAk1BMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD///96xwVnAAAAMHRSTlMABhsHCk99gFIMbnhWdnIPV1hTYHEEAmwXKklCMTBACA5iRDYneiBwaGtQDX4LKBihBOlXAAAAAWJLR0Qwrtwt5AAAAAlwSFlzAAALEgAACxIB0t1+/AAAAJ5JREFUGNNVj9sWgiAQRVHLk2GZFVaWSheNFPX//y4STdgvw2yYdQZCBhzX9cjMYukD/iqY+mANGoYbbKNR7BCr984eB91HOLJfTU44D+KCVN9ccRtqhlwLVjBbjPAU94fm+eIqs4RBGZAKbzERf1ApUc/j9SyaxhaMUmaJRMrEEi3QWqKCOprC6zrPTjFjRf5HKMGlubpUn+kzg558AQJYE4cxGDUYAAAAAElFTkSuQmCC");
}
.mompark-b2b-login .mompark-login .btn-gray {
  font-size: 20px;
  padding: 13px 22px;
}

.mompark-b2b .navbar-b2b ul {
  width: 100%;
}
.mompark-b2b .navbar-bottom-link:not(:last-child) {
  margin-right: 20px;
}
.mompark-b2b .navbar-bottom-link.active a {
  font-weight: 600;
  color: #c9baa1;
}
.mompark-b2b .header-logo {
  padding-top: 0;
}
.mompark-b2b .shop-title {
  margin-bottom: 30px;
}
.mompark-b2b .shop-title span {
  width: fit-content;
  font-size: 20px;
  padding: 10px 15px;
  margin-right: auto;
  margin-left: auto;
}
.mompark-b2b .back-to-mom {
  display: table;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: auto;
  margin-left: auto;
}
.mompark-b2b-content {
  padding-bottom: 40px;
}
.mompark-b2b-content h1, .mompark-b2b-content .h1 {
  margin-top: 30px;
  margin-bottom: 40px;
}
.mompark-b2b .tc-left a {
  text-decoration: underline;
}
.mompark-b2b .tc-left p {
  margin-bottom: 16px;
}
.mompark-b2b .tc-right.col-12 {
  margin-bottom: 30px;
}
.mompark-b2b .tc-right .inner {
  padding: 10px 5px 20px;
}
.mompark-b2b .tc-content {
  text-align: center;
  padding-top: 10px;
}
.mompark-b2b .tc-content strong {
  font-weight: 700;
}
.mompark-b2b .tc-content a {
  color: #D88373;
}
.mompark-b2b .tc-othercontent ul {
  display: table;
  max-width: 60%;
  text-align: left;
  list-style-type: disc;
  padding-left: 40px;
  margin: 20px auto 0;
}
.mompark-b2b .tc-othercontent ul a {
  color: #D88373;
}
.mompark-b2b .tab-content {
  display: none;
}
.mompark-b2b .tab-content-row {
  margin-bottom: 30px;
}
.mompark-b2b .tab-content.active {
  display: block;
}
.mompark-b2b .tab-content .col-12 .tc-left a {
  text-decoration: none;
}
.mompark-b2b .tab-content .col-12 .tc-left a:not(.btn) {
  color: #D88373;
}
.mompark-b2b .tab-content .col-6 .inner {
  height: 100%;
}
.mompark-b2b .tab-content .col-6 .tc-title span {
  text-align: center;
}

@media screen and (max-width: 1199px) {
  .mompark-b2b .navbar-desktop {
    display: block;
  }
  .tc-othercontent ul {
    max-width: 80% !important;
  }
}
@media screen and (max-width: 767px) {
  .mompark-b2b .navbar-b2b {
    margin-top: 30px;
  }
  .mompark-b2b .navbar-b2b ul.d-flex {
    flex-wrap: wrap;
  }
  .mompark-b2b .navbar-b2b .navbar-bottom-link {
    max-width: 100%;
    flex-basis: 100%;
    text-align: center;
  }
  .mompark-b2b .navbar-b2b .navbar-bottom-link:not(:last-child) {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .mompark-b2b .btn-taupe {
    margin-bottom: 30px;
  }
}