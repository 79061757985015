@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.navbar .row {
  align-items: center;
  justify-content: space-between;
  min-height: 134px;
  margin-right: 0;
  margin-left: 0;
}
.navbar-left {
  display: flex;
  align-items: center;
}
.navbar-left li + li {
  margin-left: 55px;
}
.navbar-left a {
  padding-top: 10px;
  padding-bottom: 10px;
}
.navbar-right {
  display: flex;
  align-items: center;
}
.navbar-right li.show-layer {
  cursor: pointer;
}
.navbar-right li a {
  display: block;
  color: #1D1D1B;
}
.navbar-right li i {
  font-size: 25px;
}
.navbar-right li + li {
  margin-left: 29px;
}
.navbar-right li.navbar-social {
  margin-left: 39px;
}
.navbar-lang a {
  display: flex !important;
  align-items: center;
}
.navbar-lang a i {
  margin-right: 11px;
}
.navbar-social {
  display: flex;
  align-items: center;
}
.navbar-social a + a {
  margin-left: 13px;
}
.navbar-desktop .container {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}
.navbar-desktop .container .navbar-brand {
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.navbar-desktop ul li {
  position: relative;
}
.navbar-desktop ul li:first-child {
  padding-left: 0;
}
.navbar-desktop ul li a {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  color: #1D1D1B;
  text-transform: uppercase;
  line-height: 1.1875;
  -moz-transition: color 300ms ease-in-out;
  -o-transition: color 300ms ease-in-out;
  -webkit-transition: color 300ms ease-in-out;
  transition: color 300ms ease-in-out;
}
.navbar-desktop ul li a:hover, .navbar-desktop ul li a.active {
  color: #c9baa1;
}
.navbar-bottom {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #c9baa1;
  padding-bottom: 7px;
  margin-top: -4px;
  margin-bottom: 20px;
}
.navbar-bottom-href {
  font-size: 16px;
  font-weight: 500;
  color: #1D1D1B;
  text-transform: uppercase;
  line-height: 1.1875;
  -moz-transition: color 300ms ease-in-out;
  -o-transition: color 300ms ease-in-out;
  -webkit-transition: color 300ms ease-in-out;
  transition: color 300ms ease-in-out;
  padding: 10px 0;
}
.navbar-bottom-href:hover, .navbar-bottom-href.active {
  color: #c9baa1;
}
.navbar-bottom-href.active {
  font-weight: 700;
}
.navbar-mobile {
  display: none;
  border-bottom: none;
}
.navbar-mobile:not(.navbar-brand-mobile) {
  background-color: #e6dfd4;
}
.navbar-mobile.navbar-brand-mobile a {
  display: table;
  margin: 15px auto;
}
.navbar-mobile.navbar-brand-mobile a img {
  display: inherit;
}
.navbar-mobile .container {
  display: none;
  padding-left: 15px;
  padding-right: 15px;
}
.navbar-mobile .row {
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.navbar-mobile .navbar-mobile-side {
  display: flex;
  align-items: center;
  height: 50px;
  float: right;
}
.navbar-mobile .navbar-mobile-side li {
  display: inline-block;
  padding: 10px;
}
.navbar-mobile .navbar-mobile-side li a {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  text-transform: uppercase;
}
.navbar-mobile .navbar-mobile-side li a span {
  font-size: 20px;
}
.navbar-mobile .navbar-mobile-side li i {
  font-size: 20px;
}
.navbar-mobile .navbar-mobile-side li:last-child span {
  margin-right: 6px;
}
.navbar-mobile .navbar-mobile-side li.navbar-social {
  display: flex;
  align-items: center;
}
.navbar-mobile .navbar-mobile-side li.navbar-social a + a {
  margin-left: 13px;
}
.navbar-mobile .navbar-mobile-list {
  width: 100%;
  margin: 8px 0;
}
.navbar-mobile .navbar-mobile-list li {
  text-align: center;
  padding: 5px 0;
}
.navbar-mobile .navbar-mobile-list li a {
  font-weight: 500;
  color: #000000;
  line-height: 1.5;
  text-transform: uppercase;
  text-align: center;
}
.navbar-toggler {
  cursor: pointer;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 28px;
  color: #FFFFFF;
  line-height: 1;
  background-color: #c9baa1;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box;
}
.navbar-toggler span {
  -ms-transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  -moz-transform: translateY(-3px);
  -o-transform: translateY(-3px);
  transform: translateY(-3px);
}

@media screen and (max-width: 1199px) {
  .navbar-desktop {
    display: none;
  }
  .navbar-mobile {
    display: block;
  }
}