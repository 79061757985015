@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.cookie {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 1.5em;
  background-color: rgba(29, 29, 27, 0.6);
  overflow: hidden;
  padding: 16px 29px;
  z-index: 9999;
}
.cookie-message {
  font-family: "Helvetica,Calibri,Arial", sans-serif;
  flex: 1;
}
.cookie-message a {
  display: inline-block;
  font-family: "Helvetica,Calibri,Arial", sans-serif;
  color: #FFFFFF;
  text-decoration: underline;
  opacity: 0.8;
  padding: 3px;
}
.cookie-button {
  display: flex;
  align-items: center;
  align-content: space-between;
}
.cookie-close {
  display: block;
  font-family: "Helvetica,Calibri,Arial", sans-serif;
  font-size: 14.4px;
  font-weight: 700;
  color: #1D1D1B;
  text-align: center;
  white-space: nowrap;
  background-color: #FFFFFF;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  padding: 6px 11.5px;
}

@media screen and (max-width: 574px) {
  .cookie {
    flex-direction: column;
  }
  .cookie-message {
    margin-bottom: 16px;
  }
  .cookie-button, .cookie-close {
    width: 100%;
  }
}