@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.page__csr form .row-chk {
  justify-content: center;
}

.pcsr__hero {
  margin-bottom: 3px;
}
.pcsr__header {
  margin-bottom: 34px;
}
.pcsr__content {
  margin-bottom: 38px;
}
.pcsr__content p:not(:last-child) {
  margin-bottom: 32px;
}
.pcsr__bottom {
  margin-bottom: 22px;
}
.pcsr__bottom-inner {
  padding: 41px 25px 46px;
}
.pcsr__bottom-title {
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  margin-bottom: 32px;
}
.pcsr__bottom p {
  padding-bottom: 37px;
}
.pcsr__bottom .btn {
  min-width: 249px;
  padding: 14px 10px;
  margin-right: auto;
  margin-bottom: 37px;
  margin-left: auto;
}