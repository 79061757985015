@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
/* Színek 2024 */
/* MOM Park Kártya */
* {
  box-sizing: border-box;
}

.text-uppercase {
  text-transform: uppercase;
}
.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.clear {
  min-height: 0;
}
.clear:before, .clear:after {
  content: "";
  display: table;
}
.clear:after {
  clear: both;
}

.backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url("../img/empty.svg");
  background-position: center center;
  background-repeat: repeat;
  background-size: auto;
  z-index: 998;
  background-color: rgba(29, 29, 27, 0.9);
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.backdrop.sys {
  display: block;
}

.img-resp {
  max-width: 100%;
  height: auto;
}

ul {
  list-style-type: none;
}
ul.ul {
  list-style-position: outside;
  list-style-type: disc;
}
ul.ul li {
  display: list-item;
}

.small-64 {
  font-size: 64%;
}
.small-80 {
  font-size: 80%;
}

.legal-text {
  font-size: 0.8rem;
  font-style: italic;
  text-align: left;
}

.disable {
  pointer-events: none;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive-16by9::before {
  display: block;
  content: "";
  padding-top: 56.25%;
}
.embed-responsive-21by9 {
  padding-bottom: 42.8571428571%;
}
.embed-responsive-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.show-popup {
  cursor: pointer;
}