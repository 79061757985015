@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.news-list {
  padding-bottom: 47px;
}
.news-top {
  border-bottom: 1px solid #c9baa1;
  padding-bottom: 24px;
  margin-bottom: 29px;
}
.news-top h1 {
  font-weight: 900;
  margin-bottom: 27px;
}
.news-top p {
  font-size: 20px;
  font-weight: 700;
  color: #1D1D1B;
  line-height: 1.55;
}
.news-top a {
  display: block;
  margin-bottom: 35px;
}
.news-item {
  position: relative;
  margin-bottom: 11px;
}
.news-item.col-6 {
  padding-bottom: 17px;
}
.news-inner {
  position: relative;
}
.news-date {
  position: absolute;
  left: 0;
  bottom: 6px;
  font-size: 20px;
  font-weight: 500;
  color: #1D1D1B;
  line-height: 1.2;
  text-transform: uppercase;
}
.news-image {
  max-width: 100%;
  height: 220px;
  object-fit: contain;
  margin-bottom: 10px;
}
.news-title {
  font-weight: 900;
  color: #c9baa1;
  text-transform: uppercase;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 6px;
}
.news-desc {
  margin-bottom: 25px;
}
.news-desc * {
  font-size: 20px;
  font-weight: 500;
  color: #1D1D1B;
  line-height: 1.2;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news-desc p:not(:first-child) {
  display: none;
}
.news-link {
  display: table;
  font-size: 40px;
  color: #D3CDC4;
  line-height: 1;
  margin-right: auto;
  margin-left: auto;
}
.news-link:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.news-button {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.news-button .btn {
  min-width: 216px;
  margin: 0 11px;
}

.news-item-small {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 10px;
}
.news-item-small .news-picture {
  max-width: 180px;
  flex-basis: 180px;
  min-height: 125px;
  height: 100%;
}
.news-item-small .news-content {
  max-width: calc(100% - 180px);
  flex-basis: calc(100% - 180px);
  background-color: #EEF1F2;
  padding: 15px 15px 15px 25px;
}
.news-item-small .news-title {
  font-size: 16px;
  line-height: 1.2;
  text-transform: uppercase;
}
.news-item-small .news-desc {
  font-size: 16px;
  line-height: 1.2;
}
.news-item-small .news-desc, .news-item-small .news-desc * {
  font-family: "Nexa", sans-serif !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 1.2 !important;
}

@media screen and (max-width: 1440px) {
  .news-image {
    height: 193px;
  }
}
@media screen and (max-width: 1199px) {
  .news-top img {
    margin-bottom: 80px;
  }
  .news-image {
    height: 156px;
  }
}
@media screen and (max-width: 991px) {
  .news-top h1 {
    margin-bottom: 20px;
  }
  .news-top img {
    margin-bottom: 60px;
  }
  .news-desc, .news-date {
    font-size: 18px;
    line-height: 1.4;
  }
  .news-image {
    height: 115px;
  }
}
@media screen and (max-width: 767px) {
  .news-top img {
    margin-bottom: 40px;
  }
  .news-image {
    height: 177px;
  }
}
@media screen and (max-width: 575px) {
  .news-desc, .news-date {
    font-size: 16px;
  }
  .news-image {
    height: auto;
    object-fit: unset;
  }
  .news-button .btn {
    max-width: 50%;
    flex-basis: 50%;
    min-width: unset;
  }
}