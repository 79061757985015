@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.alert {
  border: 1px solid transparent;
  padding: 12px 20px;
  margin-bottom: 16px;
}
.alert-success {
  background-color: #dff0d8;
  border-color: #d0e9c6;
  color: #3c763d;
}
.alert-danger {
  background-color: #f2dede;
  border-color: #ebcccc;
  color: #a94442;
}