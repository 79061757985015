@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.form-group {
  margin-bottom: 26px;
}
.form-group input::-webkit-input-placeholder,
.form-group textarea::-webkit-input-placeholder {
  font-style: normal;
  color: #c9baa1;
  opacity: 1;
  -moz-transition: opacity 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
}
.form-group input::-moz-placeholder,
.form-group textarea::-moz-placeholder {
  font-style: normal;
  color: #c9baa1;
  opacity: 1;
  -moz-transition: opacity 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
}
.form-group input:-ms-input-placeholder,
.form-group textarea:-ms-input-placeholder {
  font-style: normal;
  color: #c9baa1;
  opacity: 1;
  -moz-transition: opacity 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
}
.form-group-fullheight {
  height: 100%;
}
.form-label {
  display: block;
  width: 100%;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 8px;
}
.form-label a {
  text-decoration: underline;
}
.form-control {
  display: block;
  width: 100%;
  height: 55px;
  font-family: "Nexa", sans-serif;
  font-size: 20px;
  color: #c9baa1;
  line-height: 1.25;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 8px 12px;
}
.form-control-textarea {
  height: 70px;
  text-align: left;
  resize: none;
}
.form-control:active, .form-control:focus, .form-control.active {
  border-color: #D88373;
}
.form-submit {
  font-size: 20px;
  max-width: 249px;
  width: 100%;
  height: 52px;
}

.chk-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  min-height: 22px;
  padding-left: 44px;
  margin-bottom: 12px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.chk-container:hover .checkmark {
  border-color: #c9baa1;
}
.chk-container, .chk-container a {
  font-size: 12px;
  font-weight: 500;
  color: #1D1D1B;
  line-height: 18px;
}
.chk-container a {
  display: inline;
  font-weight: 600;
  color: #1D1D1B;
  text-decoration: underline;
  margin: 0 3px;
}
.chk-container a:hover {
  text-decoration: underline;
}
.chk-container label {
  margin-bottom: 0;
}
.chk-container input {
  cursor: pointer;
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
.chk-container input:checked ~ .checkmark {
  border-color: #FFFFFF;
  background-color: #FFFFFF;
}
.chk-container input:checked ~ .checkmark:after {
  display: block;
}
.chk-container .checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  width: 22px;
  height: 22px;
  border: 1px solid #FFFFFF;
  background-color: #FFFFFF;
}
.chk-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: -1px;
  left: -1px;
  width: 22px;
  height: 22px;
  background-image: url("../img/icon/check.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 15px 11px;
}

@media screen and (max-width: 991px) {
  .form-label {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .form-submit {
    max-width: 224px;
    height: 40px;
    font-size: 18px;
  }
  .form-label {
    font-size: 16px;
  }
  .form-control {
    height: 40px;
  }
}