@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.shop-category-list {
  margin-bottom: 40px;
}
.shop-category-list-item a {
  cursor: pointer;
  display: block;
  filter: grayscale(100%);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #FFFFFF;
  border: 1px solid #E1E2E6;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  padding-bottom: 100%;
  margin-bottom: 10px;
}
.shop-category-list-item a:hover {
  filter: grayscale(0);
}

@media screen and (max-width: 1199px) {
  .shop-category ul {
    column-count: 4;
  }
}
@media screen and (max-width: 991px) {
  .shop-category ul {
    column-count: 3;
  }
}
@media screen and (max-width: 767px) {
  .shop-category ul {
    column-count: 2;
  }
}
@media screen and (max-width: 574px) {
  .shop-category .row {
    margin-left: 0;
    margin-right: 0;
  }
  .shop-category ul {
    width: 100%;
    column-count: 1;
  }
}