@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.page__contact {
  padding-bottom: 29px;
}

.page-contact .form-group {
  margin-bottom: 12px;
}
.page-contact .form-control {
  height: 47px;
}
.page-contact .form-control-textarea {
  height: 76px;
}
.page-contact .form-submit {
  height: 46px;
  margin-top: 20px;
}

.pc__right {
  align-self: center;
}
.pc__inner .btn {
  max-width: 407px;
  width: 100%;
  margin-top: 6px;
}
.pc__item {
  margin-bottom: 35px;
}
.pc__item h2 {
  color: #c9baa1;
  line-height: 1.2;
  margin-bottom: -3px;
}
.pc__item i {
  font-size: 17px;
  color: #c9baa1;
  margin-right: 10px;
}
.pc__item i.wi-big {
  font-size: 24px;
}
.pc__item p, .pc__item a {
  line-height: 1.2;
}
.pc__item a:hover {
  text-decoration: underline;
}

.page-contact {
  background-color: #e6dfd4;
  padding-top: 41px;
  padding-bottom: 34px;
}
.page-contact h2 {
  font-size: 20px;
  font-weight: 900;
  color: #c9baa1;
  line-height: 1.2;
  margin-bottom: 29px;
}
.page-contact .btn {
  display: table;
  margin-right: auto;
  margin-left: auto;
}
.page-contact .form-checkbox {
  display: table;
  margin: 37px auto 0;
}
.page-contact-map {
  padding-top: 25px;
  padding-bottom: 25px;
}

@media screen and (max-width: 991px) {
  .pc__left {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  .page-contact .alert-container {
    margin-left: 0;
  }
  .page-contact .form-submit {
    display: table;
    margin-right: 0;
    margin-left: auto;
  }
}