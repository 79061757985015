@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.page__momparkcard {
  padding-bottom: 26px;
}

.services-layer {
  position: absolute;
  display: none;
  align-items: center;
  justify-content: flex-start;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  background-color: #e6dfd4;
  padding: 30px;
  z-index: 1;
}
.services-layer span {
  font-weight: 900;
}
.services-layer ul {
  padding-left: 15px;
}
.services-layer ul li {
  font-size: 16px;
}
.services-layer.sys {
  display: flex;
}

.services-layer-close {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 12px;
  right: 8px;
  width: 28px;
  height: 28px;
  z-index: 2;
}
.services-layer-close:before, .services-layer-close:after {
  content: "";
  display: block;
  width: 30px;
  height: 1px;
  background-color: #c9baa1;
  margin-left: -5px;
}
.services-layer-close:before {
  -ms-transform: rotate(45deg) translate(9px, 10px);
  -webkit-transform: rotate(45deg) translate(9px, 10px);
  -moz-transform: rotate(45deg) translate(9px, 10px);
  -o-transform: rotate(45deg) translate(9px, 10px);
  transform: rotate(45deg) translate(9px, 10px);
}
.services-layer-close:after {
  -ms-transform: rotate(-45deg) translate(-9px, 9px);
  -webkit-transform: rotate(-45deg) translate(-9px, 9px);
  -moz-transform: rotate(-45deg) translate(-9px, 9px);
  -o-transform: rotate(-45deg) translate(-9px, 9px);
  transform: rotate(-45deg) translate(-9px, 9px);
}

.pm__hero {
  margin-bottom: 15px;
}
.pm__title {
  font-size: 20px;
  font-weight: 900;
  color: #c9baa1;
  margin-bottom: 13px;
}
.pm__item input[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}
.pm__item input[type=number]::-webkit-outer-spin-button, .pm__item input[type=number]::-webkit-inner-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.pm__item p:not(:last-child) {
  margin-bottom: 30px;
}
.pm__item a {
  font-weight: 600;
  color: #c9baa1;
  text-decoration: underline;
}
.pm__item-form {
  position: relative;
  background-color: #e6dfd4;
  padding: 47px 15px 45px;
}
.pm__item-form input::-webkit-input-placeholder,
.pm__item-form textarea::-webkit-input-placeholder {
  font-style: normal;
  color: #c9baa1;
  opacity: 1;
  -moz-transition: opacity 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
}
.pm__item-form input::-moz-placeholder,
.pm__item-form textarea::-moz-placeholder {
  font-style: normal;
  color: #c9baa1;
  opacity: 1;
  -moz-transition: opacity 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
}
.pm__item-form input:-ms-input-placeholder,
.pm__item-form textarea:-ms-input-placeholder {
  font-style: normal;
  color: #c9baa1;
  opacity: 1;
  -moz-transition: opacity 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
}
.pm__item-form .pm__title {
  margin-bottom: 18px;
}
.pm__item-form input {
  display: block;
  font-size: 20px;
  color: #c9baa1;
  max-width: 436px;
  width: 100%;
  height: 55px;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
}
.pm__item-form .btn {
  max-width: 249px;
  width: 100%;
  padding: 15px 10px;
  margin-right: auto;
  margin-left: auto;
}
.pm__left .pm__item {
  margin-bottom: 35px;
}
.pm__left .pm__item.pm__item-form {
  margin-bottom: 48px;
}

@media screen and (max-width: 1199px) {
  .services-layer ul li strong {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .services-layer {
    padding: 20px 15px;
  }
}