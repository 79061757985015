@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.layer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url("../img/empty.svg");
  background-position: center center;
  background-repeat: repeat;
  background-size: auto;
  z-index: 1000;
  background-color: rgba(29, 29, 27, 0.9);
}
.layer.sys {
  display: block;
}
.layer-close {
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 0;
  font-size: 96px;
  font-weight: 300;
  color: #C8C9CB;
  line-height: 48px;
}
.layer-search .container {
  position: relative;
  padding: 0 10px;
}
.layer-search form {
  margin-top: 10%;
}
.layer-search-group {
  margin-bottom: 16px;
}
.layer-search-input {
  width: 100%;
  height: 80px;
  font-size: 40px;
  color: #C8C9CB;
  background-color: transparent;
  border-bottom: 2px solid #C8C9CB;
  padding: 12px 24px;
}
.layer-search-input:focus {
  color: #C8C9CB;
  border-color: #C8C9CB;
}
.layer-search .result-list {
  padding-top: 19px;
}
.layer-search .result-list p {
  font-size: 20px;
  font-weight: 700;
  color: #C8C9CB;
  line-height: 1.1;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.layer-search .result-list a {
  display: block;
  font-size: 20px;
  color: #C8C9CB;
  line-height: 1.1;
  margin-bottom: 8px;
}

@media screen and (max-width: 991px) {
  .layer-search-input {
    height: 55px;
    font-size: 24px;
  }
}