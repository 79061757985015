@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.page-404 {
  padding-bottom: 40px;
}
.page-404 img {
  display: table;
  margin: 50px auto;
}
.page-404 p {
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  text-align: center;
}