@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.breadcrumb {
  text-align: center;
  background-color: #ECEEEF;
  padding: 12px 16px;
  margin: 0 0 10px;
}
.breadcrumb a {
  font-size: 13px;
  color: #1D1D1B;
  line-height: 1.5;
  text-transform: uppercase;
}
.breadcrumb a.active {
  font-weight: 900;
}
.breadcrumb a:hover {
  color: #52524C;
}
.breadcrumb li {
  display: inline-block;
}
.breadcrumb li:after {
  content: "›";
  display: inline-block;
  color: #1D1D1B;
  padding-right: 9px;
  padding-left: 6px;
}
.breadcrumb li:last-child:after {
  display: none;
}

@media screen and (max-width: 574px) {
  .breadcrumb {
    margin: 0 0 10px;
  }
}