@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.tc-christmas h1 {
  font-size: 43px;
}
.tc-christmas .tc-header, .tc-christmas .tc-footer {
  margin: 0 5px;
}
.tc-christmas .tc-header {
  text-align: center;
  min-height: 300px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;
  padding-bottom: 0;
}
.tc-christmas .tc-header p {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 8px;
}
.tc-christmas .tc-left {
  max-width: 33.33333%;
  flex-basis: 33.33333%;
}
.tc-christmas .tc-left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tc-christmas .tc-right {
  max-width: 66.66667%;
  flex-basis: 66.66667%;
}
.tc-christmas .tc-right .inner {
  color: #FFFFFF;
  background-color: transparent;
  height: 100%;
  padding: 0;
}
.tc-christmas .tc-right .inner .tc-inner-new {
  padding: 28px 20px;
}
.tc-christmas .tc-right .inner p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 16px;
}
.tc-christmas .tc-right .inner a {
  display: block;
  max-width: 380px;
  font-size: 20px;
  font-weight: 700;
  color: #FFFFFF;
  text-align: center;
  text-transform: uppercase;
  background-color: #FFFFFF;
  padding: 12px 10px;
  margin: 24px auto 0;
}
.tc-christmas .tc-right .inner h3 {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.tc-christmas .tc-right .inner ul {
  list-style-type: disc;
  padding-left: 40px;
}
.tc-christmas .tc-right .inner ul li {
  display: list-item;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.3;
}
.tc-christmas .tc-footer {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;
  padding: 37px 15px 25px;
}
.tc-christmas .tc-footer h3, .tc-christmas .tc-footer p {
  font-size: 20px;
}
.tc-christmas .tc-footer h3 {
  font-weight: 600;
  margin-bottom: 16px;
}
.tc-christmas .tc-footer p {
  line-height: 1.4;
}
.tc-christmas-booking {
  padding-bottom: 96px;
  margin-bottom: 40px;
}
.tc-christmas-booking-content {
  text-align: center;
  padding-top: 48px;
}
.tc-christmas-booking-content h2 {
  margin-bottom: 56px;
}
.tc-christmas-booking form h5, .tc-christmas-booking form p {
  text-align: center;
}
.tc-christmas-booking form h5 {
  margin-bottom: 5px;
}
.tc-christmas-booking form p {
  margin-top: 8px;
  margin-bottom: 16px;
}
.tc-christmas-booking form .form-label {
  text-align: center;
}
.tc-christmas-booking form .form-group {
  margin-bottom: 16px;
}
.tc-christmas-booking form .row-column {
  flex-direction: column;
}
.tc-christmas-booking form .row-column .form-label {
  text-align: left;
}
.tc-christmas-booking form .row-column .form-submit {
  margin-top: 25px;
  margin-bottom: 40px;
}
.tc-christmas-booking-partner {
  display: table;
  margin: 0 auto;
}

@media screen and (max-width: 1199px) {
  .tc-christmas .tc-header {
    background-position: left calc(100% - 20px);
  }
}
@media screen and (max-width: 991px) {
  .tc-christmas .tc-header {
    min-height: 260px;
  }
  .tc-christmas .tc-right, .tc-christmas .tc-left {
    max-width: 100%;
    flex-basis: 100%;
  }
  .tc-christmas h1 {
    font-size: 33px;
  }
}
@media screen and (max-width: 768px) {
  .tc-christmas .tc-header {
    min-height: 200px;
  }
  .tc-christmas h1 {
    font-size: 25px;
  }
}
@media screen and (max-width: 575px) {
  .tc-christmas .tc-header {
    min-height: unset;
    background-image: none !important;
    margin-bottom: 20px;
  }
}