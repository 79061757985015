@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.page__calendarium {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-x: hidden;
  padding-top: 60px;
  padding-bottom: 60px;
}
.page__calendarium h1 {
  margin-bottom: 40px;
}
.page__calendarium p.text-center {
  margin-bottom: 40px;
}

.calendar {
  display: none;
  width: 1140px;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 1fr);
  margin-right: auto;
  margin-left: auto;
}
.calendar.sys {
  display: grid;
}

.day, .empty {
  position: relative;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  font-family: "Rozha One", sans-serif;
  font-size: 90px;
  color: #F9F3DF;
  background-color: #576C4D;
  border: 1px solid #F9F3DF;
  transition: transform 0.3s ease;
}

.empty a {
  display: inherit;
}

.day {
  background-image: url("../img/calendarium/hanger.svg");
  background-position: center 20px;
  background-repeat: no-repeat;
  background-size: auto;
  min-height: 190px;
}
.day#current-day {
  position: relative;
  grid-column: 2/span 3;
  grid-row: 2/span 2;
  height: 380px;
  font-size: 380px;
  color: rgba(0, 0, 0, 0.57);
  background-image: url("../img/calendarium/box.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
}
.day#current-day .current-day-text {
  max-width: 370px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 1.2;
  z-index: 1;
}
.day#current-day .current-day-text span {
  display: block;
  font-size: 14px;
  line-height: 1.2;
}
.day#current-day .line {
  display: block;
  position: absolute;
  width: 42px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #F9F2DE;
  z-index: 1;
}
.day.line {
  position: relative;
}
.day.line:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 42px;
  background-color: #F9F2DE;
  z-index: 1;
}
.day.line.bottom:before {
  bottom: 0;
  left: 0;
}
.day.line.left:before, .day.line.right:before {
  width: 42px;
  height: 100%;
  top: 0;
}
.day.line.left:before {
  left: 0;
}
.day.line.right:before {
  right: 0;
}

.backdoor {
  position: absolute;
  top: 0;
  left: 0;
  width: 568px;
  height: 378px;
  z-index: 2;
}

.door {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #576C4D;
  background-image: url("../img/calendarium/bg.svg");
  background-position: 50px calc(100% - 34px);
  background-repeat: no-repeat;
  background-size: 494px 263px;
  font-size: 290px;
  transform-origin: bottom;
  transition: all 0.5s ease-in-out;
  z-index: 2;
}
.door:before {
  content: attr(data-text);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: "Rozha One", sans-serif;
  color: #F9F3DF;
  transition: opacity 100ms ease;
  opacity: 1;
}
.door:after {
  opacity: 0;
  visibility: hidden;
  display: block;
  background-color: #576C4D;
  content: "";
  width: calc(94% + 4px);
  height: 153px;
  transition-delay: 300ms;
  transition: opacity 100ms ease, background-color 100ms ease;
  transform: perspective(1200px) translateZ(0) translateX(14px) translateY(-119px) rotateX(-126deg);
}

.door.open {
  transform: perspective(1200px) translateZ(0px) translateX(0px) translateY(0px) rotateX(-96deg);
  background-color: #41573C;
  box-shadow: -10px -250px 70px -80px rgba(0, 0, 0, 0.5);
}
.door.open:after {
  opacity: 1;
  visibility: visible;
  background-color: #576C4D;
  transition-delay: 300ms;
}
.door.open:before {
  opacity: 0;
  transition-delay: 300ms;
}
.door.open.bg {
  background-image: none;
}
.door.open.bg .line {
  display: none !important;
}

.door-hanger {
  display: none;
  position: absolute;
  bottom: -96px;
  z-index: 10;
}

@media screen and (max-width: 1199px) {
  .calendar {
    width: 950px;
  }
  .day, .empty {
    font-size: 75px;
  }
  .empty img {
    width: 100px;
  }
  .day {
    min-height: 158.33px;
  }
  .day#current-day {
    height: 316.66px;
    font-size: 300px;
    background-size: 100% 100%;
  }
  .day.line:before {
    height: 30px;
  }
  .day.line.left:before, .day.line.right:before {
    width: 30px;
  }
  .backdoor {
    width: 473px;
    height: 316.66px;
  }
  .door {
    font-size: 250px;
    background-size: 90%;
  }
  .door.open {
    transform: perspective(1200px) translateZ(0) translateX(0) translateY(-2px) rotateX(-96deg);
  }
  .door-hanger {
    bottom: -80px;
  }
}
@media screen and (max-width: 991px) {
  .calendar {
    width: 720px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(7, 1fr);
  }
  .day#current-day {
    height: 360px;
    font-size: 250px;
    grid-column: 1/span 4;
    grid-row: 2/span 2;
    background-image: url("../img/calendarium/box-tablet.png");
  }
  .day#current-day .current-day-text {
    max-width: 100%;
    font-size: 14px;
    padding: 0 10px;
  }
  .day#current-day .current-day-text span {
    font-size: 12px;
  }
  .day {
    min-height: 180px;
  }
  .door {
    font-size: 200px;
  }
  .backdoor {
    width: 100%;
    height: 360px;
  }
  .door-hanger {
    bottom: -91px;
  }
}
@media screen and (max-width: 768px) {
  .calendar {
    max-width: 540px;
  }
  .day, .empty {
    font-size: 65px;
  }
  .day#current-day {
    height: 270px;
    font-size: 200px;
  }
  .day {
    min-height: 135px;
  }
  .day.line:before {
    height: 20px;
  }
  .day.line.left:before, .day.line.right:before {
    width: 20px;
  }
  .backdoor {
    height: 270px;
  }
  .door-hanger {
    bottom: -72px;
  }
  .door {
    font-size: 170px;
  }
  .door:after {
    transform: perspective(1200px) translateZ(0) translateX(14px) translateY(-122px) rotateX(-130deg);
  }
}
@media screen and (max-width: 660px) {
  .calendar {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(14, 1fr);
  }
  .day, .empty {
    font-size: 55px;
  }
  .day#current-day {
    font-size: 180px;
    grid-column: 1/span 2;
    grid-row: 2/span 2;
  }
}