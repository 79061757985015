@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.shop-category {
  margin-bottom: 37px;
}
.shop-category ul {
  font-weight: 500;
  margin-bottom: -10px;
}
.shop-category ul.sc-catlist {
  max-width: 70%;
  flex-basis: 70%;
  column-count: 3;
  column-gap: 20px;
}
.shop-category ul.sc-catlist li {
  cursor: pointer;
  margin-bottom: 10px;
}
.shop-category ul.sc-catlist li.active {
  font-weight: 600;
}
.shop-category ul.sc-catlist li:hover {
  font-weight: 600;
}
.shop-category ul.sc-extralist {
  max-width: 30%;
  flex-basis: 30%;
  font-size: 14px;
}
.shop-category ul.sc-extralist li {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.shop-category ul.sc-extralist li.active {
  font-weight: 900;
}
.shop-category ul.sc-extralist li:hover {
  font-weight: 600;
}
.shop-category ul.sc-extralist li.showall {
  padding: 5px 10px;
}
.shop-category ul.sc-extralist li span {
  display: inline-block;
  min-width: 44px;
  text-align: center;
  margin-right: 13px;
}
.shop-category ul.sc-extralist li i {
  font-size: 28px;
  line-height: 1;
}
.shop-title {
  position: relative;
}
.shop-title:before {
  content: "";
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 0.5px;
  background-color: #c9baa1;
}
.shop-title span {
  position: relative;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 30px;
  background-color: #FFFFFF;
  font-size: 16px;
  font-weight: 900;
  color: #c9baa1;
  margin-right: auto;
  margin-left: auto;
  z-index: 1;
}
.shop-list {
  padding-bottom: 41px;
}
.shop-list ul {
  padding: 15px 0;
  margin-right: -20px;
  margin-bottom: -10px;
  margin-left: -20px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}
.shop-list ul li {
  margin: 0 20px 10px;
}
.shop-list ul li a {
  position: relative;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
}
.shop-list ul li a .discount {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #757879;
  opacity: 0;
}
.shop-list ul li a .discount p {
  font-size: 18px;
  color: #FFFFFF;
  text-align: center;
}
.shop-list ul li a .discount strong {
  display: block;
  font-size: 22px;
}
.shop-list ul li a:hover .discount {
  opacity: 1;
}
.shop-list ul li img {
  max-width: 130px;
  width: 100%;
  height: auto;
  opacity: 0.1;
  filter: grayscale(1);
}
.shop-list ul li:hover img {
  opacity: 0.8;
  filter: grayscale(0);
}
.shop-list ul li.active img {
  opacity: 1;
  filter: grayscale(0);
}
.shop-list-title.h2 {
  font-weight: 900;
  color: #c9baa1;
  margin-bottom: 6px;
}
.shop-list-subtitle {
  font-weight: 900;
  font-size: 20px;
  color: #c9baa1;
  margin-bottom: 28px;
}

.sc__inner {
  background-color: #e6dfd4;
  padding: 36px 35px;
}
.sc__mobile {
  cursor: pointer;
  display: none;
  color: #c9baa1;
  background-color: #e6dfd4;
  padding: 5px 15px;
}
.sc__mobile p {
  font-weight: 900;
}
.sc__mobile-bottom {
  padding-top: 0;
}
.sc__mobile.active i:before {
  -ms-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

@media screen and (max-width: 1440px) {
  .sc__inner {
    padding: 36px 25px;
  }
}
@media screen and (max-width: 1199px) {
  .sc__inner {
    flex-wrap: wrap;
  }
  .shop-list ul {
    flex-wrap: wrap;
  }
  .shop-category ul.sc-catlist {
    column-count: 3;
    max-width: 100%;
    flex-basis: 100%;
  }
  .shop-category ul.sc-extralist {
    column-count: 2;
    max-width: 100%;
    flex-basis: 100%;
    margin-top: 20px;
  }
}
@media screen and (max-width: 991px) {
  .shop-category ul.sc-catlist {
    column-count: 2;
  }
  .shop-listsubtitle {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  .sc__inner {
    display: none;
  }
  .sc__mobile {
    display: block;
  }
}
@media screen and (max-width: 575px) {
  .shop-category ul.sc-catlist {
    column-count: 1;
    column-gap: unset;
    text-align: center;
  }
  .shop-category ul.sc-extralist {
    column-count: 1;
    column-gap: unset;
    max-width: 200px;
    flex-basis: 200px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
  .shop-category ul.sc-extralist li {
    justify-content: center;
  }
}