@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
body h1, body .h1, body h2, body .h2, body h3, body .h3, body h4, body .h4, body h5, body .h5, body h6, body .h6 {
  font-weight: 700;
  line-height: 1.1;
}

body h1, body .h1 {
  font-size: 42px;
  font-weight: 900;
  color: #c9baa1;
}

body h2, body .h2 {
  font-weight: 900;
  font-size: 30px;
}

body h3, body .h3 {
  font-size: 28px;
}

body h4, body .h4 {
  font-size: 20px;
  font-weight: 900;
}

body h5, body .h5 {
  font-size: 20px;
}

body h6, body .h6 {
  font-size: 16px;
}

body a, body .a, body p, body .p, body span, body ul, body ol {
  font-weight: 500;
  line-height: 1.5;
}

@font-face {
  font-family: "Nexa";
  src: url("../fonts/2024/Nexa-Light.eot");
  src: url("../fonts/2024/Nexa-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/2024/Nexa-Light.woff2") format("woff2"), url("../fonts/2024/Nexa-Light.woff") format("woff"), url("../fonts/2024/Nexa-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Nexa";
  src: url("../fonts/2024/Nexa-Regular.eot");
  src: url("../fonts/2024/Nexa-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/2024/Nexa-Regular.woff2") format("woff2"), url("../fonts/2024/Nexa-Regular.woff") format("woff"), url("../fonts/2024/Nexa-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Nexa";
  src: url("../fonts/2024/Nexa-Book.eot");
  src: url("../fonts/2024/Nexa-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/2024/Nexa-Book.woff2") format("woff2"), url("../fonts/2024/Nexa-Book.woff") format("woff"), url("../fonts/2024/Nexa-Book.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Nexa";
  src: url("../fonts/2024/Nexa-Bold.eot");
  src: url("../fonts/2024/Nexa-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/2024/Nexa-Bold.woff2") format("woff2"), url("../fonts/2024/Nexa-Bold.woff") format("woff"), url("../fonts/2024/Nexa-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Nexa";
  src: url("../fonts/2024/Nexa-Black.eot");
  src: url("../fonts/2024/Nexa-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/2024/Nexa-Black.woff2") format("woff2"), url("../fonts/2024/Nexa-Black.woff") format("woff"), url("../fonts/2024/Nexa-Black.ttf") format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "Rozha One";
  src: url("../fonts/2024/RozhaOne-Regular.eot");
  src: url("../fonts/2024/RozhaOne-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/2024/RozhaOne-Regular.woff2") format("woff2"), url("../fonts/2024/RozhaOne-Regular.woff") format("woff"), url("../fonts/2024/RozhaOne-Regular.ttf") format("truetype");
  font-weight: 400;
}
@media screen and (max-width: 1199px) {
  body h1, body .h1 {
    font-size: 35px;
  }
  body h2, body .h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 991px) {
  body h1, body .h1 {
    font-size: 30px;
  }
  body h2, body .h2 {
    font-size: 23px;
  }
}
@media screen and (max-width: 575px) {
  body h1, body .h1 {
    font-size: 27px;
  }
  body h2, body .h2 {
    font-size: 20px;
  }
}