@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.btn {
  cursor: pointer;
  display: inline-block;
  font-weight: 900;
  color: #FFFFFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 22px;
  vertical-align: middle;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 0;
  background-clip: border-box;
}
.btn-full {
  width: 100%;
  padding: 13px 5px;
}
.btn-thin {
  font-weight: 300;
}
.btn-black {
  color: #FFFFFF;
  background-color: #000000;
  border-color: #000000;
}
.btn-beige {
  background-color: #C5976E;
  border-color: #C5976E;
}
.btn-gray {
  font-size: 16px;
  background-color: #757879;
  border-color: #757879;
  padding: 3px 22px;
}
.btn-gray:hover, .btn-gray.active {
  background-color: #5C5E5F;
  border-color: #5C5E5F;
}
.btn-pink {
  background-color: #E36CA6;
  border-color: #E36CA6;
  padding: 13px 5px;
}
.btn-pink:hover, .btn-pink.active {
  background-color: #DB418C;
  border-color: #DB418C;
}
.btn-taupe {
  font-size: 20px;
  color: #c9baa1;
  line-height: 1;
  background-color: #e6dfd4;
  padding: 11.5px 10px;
}
.btn-darktaupe {
  font-size: 20px;
  color: #e6dfd4;
  line-height: 1;
  background-color: #c9baa1;
  padding: 11.5px 10px;
}
.btn-border-darktaupe {
  font-size: 16px;
  line-height: 1;
  color: #c9baa1;
  background-color: #FFFFFF;
  border: 1px solid #c9baa1;
  padding: 13px 10px;
}

@media screen and (max-width: 991px) {
  .btn-taupe {
    font-size: 18px;
  }
}
@media screen and (max-width: 575px) {
  .btn-taupe {
    font-size: 16px;
  }
}