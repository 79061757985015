@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
header {
  position: relative;
}

.header-logo {
  justify-content: center;
  padding-top: 67px;
  padding-bottom: 67px;
}

@media screen and (max-width: 991px) {
  .header-logo {
    padding: 50px 0;
  }
}
@media screen and (max-width: 767px) {
  .header-logo {
    padding: 40px 0 20px;
  }
}
@media screen and (max-width: 574px) {
  .header-logo {
    padding: 20px 0;
  }
}