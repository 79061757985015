@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.page__giveaway form .row-chk {
  justify-content: center;
}
.page__giveaway form .btn {
  margin-top: 2px;
}
.page__giveaway .shop-title {
  margin-bottom: 15px;
}
.page__giveaway .shop-title span {
  display: table;
  font-size: 24px;
  width: auto;
  padding: 0 20px;
}
.page__giveaway .col-uploads {
  margin-top: 40px;
}
.page__giveaway .col-uploads p {
  margin-bottom: 20px;
}

.pg__inner {
  padding: 42px 25px 46px;
  margin-bottom: 12px;
}
.pg__hero {
  margin-bottom: 33px;
}
.pg__header {
  text-align: center;
  margin-bottom: 37px;
}
.pg__header h2 {
  margin-bottom: 35px;
}
.pg__header ul {
  list-style-type: disc;
  list-style-position: inside;
}
.pg__header ul li {
  margin-bottom: 1px;
}
.pg__title {
  font-size: 20px;
  font-weight: 900;
  color: #c9baa1;
  margin-bottom: 47px;
}
.pg__cover {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.pg__cover-left img {
  display: inherit;
}
.pg__cover-right {
  flex: 1;
}
.pg__cover-right * {
  font-size: 30px;
  font-weight: 900;
  text-align: center;
  line-height: 1.2;
}
.pg__week-title {
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  margin-top: 23px;
  margin-bottom: 44px;
}
.pg__week-item {
  display: flex;
  align-items: center;
  height: 200px;
  margin-bottom: 23px;
}
.pg__week-left {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  max-width: 258px;
  flex-basis: 258px;
  height: 100%;
  padding: 0 15px;
}
.pg__week-right {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  padding: 0 15px;
}
.pg__week-right * {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  line-height: 1.1;
}

@media screen and (max-width: 1440px) {
  .pg__cover-left {
    max-width: 500px;
    flex-basis: 500px;
  }
  .pg__week-left {
    max-width: 200px;
    flex-basis: 200px;
  }
  .pg__week-left img {
    width: 140px;
  }
}
@media screen and (max-width: 1199px) {
  .pg__title {
    margin-bottom: 30px;
  }
  .pg__inner {
    padding: 30px 25px;
  }
  .pg__cover-left {
    max-width: 400px;
    flex-basis: 400px;
  }
  .pg__cover-right {
    font-size: 25px;
  }
  .pg__week-item {
    flex-wrap: wrap;
    height: 100%;
    margin-bottom: 0;
  }
  .pg__week-left {
    max-width: 100%;
    flex-basis: 100%;
    height: unset;
    padding: 20px 15px;
  }
  .pg__week-left img {
    width: 100px;
  }
  .pg__week-right {
    height: calc(100% - 107px);
    padding: 20px 25px;
  }
  .col-6.col-md-12 {
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 991px) {
  .pg__cover {
    flex-wrap: wrap;
  }
  .pg__cover-left {
    max-width: 100%;
    flex-basis: 100%;
  }
  .pg__cover-left img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
  }
  .pg__cover-right {
    padding: 40px 15px;
  }
  .pg__week-left {
    padding: 10px 15px;
  }
  .pg__week-right {
    height: unset;
  }
}
@media screen and (max-width: 768px) {
  .pg__header ul li {
    font-size: 16px;
  }
  .pg__inner {
    padding: 25px 15px;
  }
  .pg__cover-right {
    font-size: 20px;
  }
}