@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.news-page {
  margin-bottom: 40px;
}
.news-page table {
  border-color: #373A3C;
}
.news-page .news-hero {
  display: table;
  margin-left: auto;
  margin-bottom: 33px;
  margin-right: auto;
}
.news-page .news-header {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 41px;
}
.news-page .news-header h1 {
  max-width: calc(100% - 280px);
  flex-basis: calc(100% - 280px);
  text-align: center;
}
.news-page .news-date {
  position: static;
  max-width: 140px;
  flex-basis: 140px;
}
.news-page .news-share {
  display: flex;
  align-items: center;
  max-width: 140px;
  flex-basis: 140px;
}
.news-page .news-share a {
  display: block;
  margin: 0 6px;
}
.news-page .news-share i {
  font-size: 25px;
  color: #c9baa1;
}
.news-page .news-bottom {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
}
.news-page .news-bottom i {
  font-size: 40px;
  color: #c9baa1;
}
.news-page .news-bottom .btn {
  min-width: 339px;
  margin-right: auto;
  margin-left: auto;
}
.news-page .news-prev {
  font-size: 0;
}
.news-page .news-prev i:before {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.news-page .news-next {
  font-size: 0;
}
.news-page .news-next i:before {
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.news-page-content h2, .news-page-content h3, .news-page-content h4, .news-page-content h5, .news-page-content h6, .news-page-content p, .news-page-content span, .news-page-content ul, .news-page-content ol, .news-page-content table, .news-page-content iframe, .news-page-content div {
  margin-bottom: 16px;
}
.news-page-content ul {
  padding-left: 15px;
  margin-bottom: 12px;
}
.news-page-content ul li {
  text-align: left;
  margin-bottom: -1px;
}
.news-page-content ul li:before {
  content: "●";
  display: inline-block;
  width: 16px;
  color: #CBA27D;
  margin-left: -16px;
}
.news-page-app .news-header, .news-page-cinema .news-header {
  justify-content: center;
}
.news-page-app-img, .news-page-cinema-img {
  display: inherit;
  width: auto;
  height: 40px;
}

@media screen and (max-width: 1199px) {
  .news-page .news-header {
    flex-wrap: wrap;
  }
  .news-page .news-header h1 {
    max-width: 100%;
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .news-page .news-date {
    order: 2;
    max-width: 50%;
    flex-basis: 50%;
  }
  .news-page .news-share {
    order: 3;
    max-width: 50%;
    flex-basis: 50%;
    justify-content: flex-end;
  }
}
@media screen and (max-width: 768px) {
  .news-page .news-bottom {
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .news-page .news-bottom .btn {
    order: 1;
    min-width: 315px;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto;
  }
  .news-page .news-bottom .news-prev {
    display: flex;
    align-items: center;
    font-size: 16px;
    order: 2;
    max-width: 50%;
    flex-basis: 50%;
  }
  .news-page .news-bottom .news-prev i {
    margin-right: 10px;
  }
  .news-page .news-bottom .news-next {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px;
    order: 3;
    max-width: 50%;
    flex-basis: 50%;
  }
  .news-page .news-bottom .news-next i {
    margin-left: 10px;
  }
}