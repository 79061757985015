@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.slick-dots {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 20px;
  z-index: 10;
}
.slick-dots button {
  cursor: pointer;
  position: relative;
  font-size: 0;
  text-indent: -9999px;
  width: 34px;
  height: 34px;
  border: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box;
}
.slick-dots button:before {
  content: "•";
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: "slick";
  font-size: 20px;
  color: #FFFFFF;
  text-indent: 0;
}
.slick-active button {
  border: 1px solid #FFFFFF;
}