@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.box-list {
  padding-bottom: 24px;
}
.box-list h1, .box-list h2 {
  margin-bottom: 30px;
}
.box-list h1 {
  font-weight: 600;
}
.box-list p {
  margin-bottom: 45px;
}
.box-list p.h5 {
  font-weight: 400;
}
.box-list-item {
  position: relative;
}
.box-list-item a {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.box-list-item img {
  width: 100%;
  height: auto;
}
.box-list-item span {
  display: inline-block;
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  width: calc(100% - 30px);
  font-size: 20px;
  font-weight: 600;
  color: #FFFFFF;
  text-transform: uppercase;
  text-align: center;
  background-color: #c9baa1;
  padding: 15.5px 15px;
}

@media screen and (max-width: 1199px) {
  .box-list-item {
    margin-bottom: 20px;
  }
}