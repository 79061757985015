@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.footer {
  padding-bottom: 40px;
}
.footer nav {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #c9baa1;
  padding-top: 10px;
  margin-bottom: 17px;
}
.footer nav a {
  font-size: 16px;
  font-weight: 500;
  color: #1D1D1B;
  line-height: 1.1875;
  padding: 10px 0;
}
.footer nav a:hover, .footer nav a.active {
  color: #c9baa1;
}
.footer .row {
  justify-content: center;
}
.footer .row a:hover {
  color: #1D1D1B;
}
.footer .row a:hover i {
  color: #c9baa1;
}
.footer .row a, .footer .row p {
  color: #c9baa1;
}
.footer .row i {
  font-size: 20px;
}
.footer__item {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.footer__item i {
  margin-right: 10px;
}
.footer__item + .footer__item {
  margin-left: 65px;
}
.footer .copyright:not(.copyright-text) {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
  margin-bottom: 14px;
}
.footer .copyright .footer-link-0 {
  order: 1;
}
.footer .copyright p {
  order: 2;
}
.footer .copyright .footer-link-1 {
  order: 3;
}
.footer .copyright a, .footer .copyright p {
  font-size: 13px;
  color: #1D1D1B;
}
.footer .copyright a:hover {
  color: #c9baa1;
}

@media screen and (max-width: 991px) {
  .footer .copyright:not(.copyright-text) {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .footer .copyright:not(.copyright-text) a {
    display: table;
    margin-right: auto;
    margin-bottom: 14px;
    margin-left: auto;
  }
  .footer .copyright:not(.copyright-text) p {
    order: 12;
  }
}
@media screen and (max-width: 768px) {
  .footer nav {
    flex-wrap: wrap;
  }
  .footer nav a {
    max-width: 50%;
    flex-basis: 50%;
  }
  .footer nav a:nth-child(even) {
    text-align: right;
  }
}
@media screen and (max-width: 575px) {
  .footer__item {
    max-width: 100%;
    flex-basis: 100%;
    justify-content: center;
    text-align: center;
  }
  .footer__item + .footer__item {
    margin-left: 0;
  }
  .footer nav {
    flex-wrap: wrap;
  }
  .footer nav a {
    max-width: 100%;
    flex-basis: 100%;
    text-align: center;
  }
  .footer nav a:nth-child(even) {
    text-align: center;
  }
}