@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.page__newsletter-hero {
  margin-bottom: 32px;
}
.page__newsletter-forminner {
  background-color: #e6dfd4;
  padding-top: 41px;
  padding-bottom: 45px;
  margin-bottom: 38px;
}
.page__newsletter-forminner form {
  text-align: center;
}
.page__newsletter-forminner form .js-error-msg {
  color: #f00;
  font-weight: 600;
}
.page__newsletter-forminner .form-group {
  max-width: 625px;
  margin-right: auto;
  margin-left: auto;
}
.page__newsletter-forminner .form-group .chk-container {
  display: inline-flex;
  margin-right: auto;
  margin-left: auto;
}
.page__newsletter-forminner .btn {
  margin-right: auto;
  margin-left: auto;
}
.page__newsletter-header h2 {
  margin-bottom: 35px;
}
.page__newsletter-content {
  margin-bottom: 38px;
}
.page__newsletter-content p:not(:last-child) {
  margin-bottom: 32px;
}
.page__newsletter-title {
  font-size: 20px;
  font-weight: 900;
  color: #c9baa1;
  line-height: 1.2;
  margin-bottom: 44px;
}

.tc-newsletter {
  padding-bottom: 11px;
}
.tc-newsletter .tc-right .inner {
  height: 100%;
  background-color: #DACAAA;
  padding: 23px 45px;
}
.tc-newsletter .tc-right .inner .title {
  font-size: 29px;
  font-weight: 600;
  color: #000000;
  text-transform: uppercase;
  margin-bottom: 19px;
}
.tc-newsletter .tc-right .inner p {
  font-weight: 400;
  line-height: 1.2;
}

.newsletter-winner .winner-item {
  display: flex;
  margin: 10px 0;
}
.newsletter-winner .winner-item .inner {
  width: 100%;
  text-align: center;
  background-color: #EEF1F2;
  padding: 15px;
}
.newsletter-winner .winner-item .inner img {
  width: auto;
  height: 95px;
}
.newsletter-winner .winner-item .inner p {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 16px;
}

.form-newsletter {
  padding-bottom: 40px;
}
.form-newsletter form {
  max-width: 370px;
  text-align: center;
  margin: 0 auto;
}
.form-newsletter .form-control {
  background-color: #EEF1F2;
  margin-bottom: 16px;
}
.form-newsletter .form-label-checkbox {
  margin-bottom: 24px;
}
.form-newsletter .form-submit {
  max-width: 258px;
}

@media screen and (max-width: 1199px) {
  .tc-newsletter .tc-right .inner {
    padding: 10px;
  }
  .tc-newsletter .tc-right .inner .title {
    font-size: 22px;
  }
  .tc-newsletter .tc-right .inner p {
    font-size: 14px;
  }
}
@media screen and (max-width: 574px) {
  .form-newsletter form {
    max-width: 230px;
  }
}