@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.slider-main-items {
  display: none;
  max-width: 100%;
  flex-basis: 100%;
  margin-bottom: 39px;
}
.slider-main-items.slick-initialized {
  display: block;
}

@media screen and (max-width: 767px) {
  .slider-main-items {
    padding: 0 5px;
  }
}