@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.page__shoppingdays-hero {
  margin-bottom: 34px;
}
.page__shoppingdays-header h2 {
  margin-bottom: 34px;
}
.page__shoppingdays-header p {
  margin-bottom: 35px;
}
.page__shoppingdays-header p strong {
  font-weight: 600;
}
.page__shoppingdays-header .btn {
  max-width: 374px;
  width: 100%;
  padding: 15px 10px;
  margin-bottom: 18px;
}
.page__shoppingdays .shop-title {
  display: block;
  margin-bottom: 15px;
}
.page__shoppingdays .shop-title span {
  display: table;
  font-size: 20px;
  width: auto;
  padding: 0 20px;
}
.page__shoppingdays .shop-category {
  margin-top: 30px;
}

.psd__icon {
  font-size: 50px;
  color: #c9baa1;
}

.coupon {
  padding-bottom: 40px;
}
.coupon-box {
  max-width: 25%;
  flex-basis: 25%;
  padding: 0 15px;
}
.coupon-box.hys {
  display: none;
}
.coupon-item {
  position: relative;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 18px;
}
.coupon-item:hover .coupon-text {
  opacity: 1;
}
.coupon-left, .coupon-right {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  max-width: 50%;
  flex-basis: 50%;
  min-height: 160px;
}
.coupon-left img {
  max-width: 120px;
  max-height: 118px;
}
.coupon-right img {
  max-height: 100px;
}
.coupon-text {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  flex-basis: 100%;
  width: 100%;
  height: 100%;
  max-height: 160px;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
  line-height: 1.1;
  opacity: 0;
  background-color: #FFFFFF;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  padding: 20px 5px;
}
.coupon .btn-border {
  font-size: 20px;
  line-height: 1.5;
  border-width: 2px;
  padding: 12px 5px;
  margin-bottom: 16px;
}
.coupon .shop-category li.showall {
  display: none;
}
.coupon .shop-category li.showall.sys {
  display: list-item;
}
.coupon .shop-category li a {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  text-transform: uppercase;
  text-align: center;
}
.coupon .shop-category li a:hover, .coupon .shop-category li a.active {
  background-color: #FEE9E7;
}
.coupon .shop-category li .btn-black {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding: 3px;
}
.coupon .small-80 {
  color: #000000;
  text-align: center;
  margin-bottom: 16px;
}

@media screen and (max-width: 1440px) {
  .coupon-box {
    max-width: 33.33333333%;
    flex-basis: 33.33333333%;
  }
  .psd__box p br {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .coupon-box {
    max-width: 50%;
    flex-basis: 50%;
  }
  .psd__box:not(:last-child) {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  .coupon-left, .coupon-right {
    min-height: 108px;
    padding: 10px 15px;
  }
  .coupon-left img {
    max-height: 80px;
  }
  .coupon-right {
    padding: 10px 15px;
  }
  .coupon-right img {
    max-height: 60px;
  }
  .coupon-text {
    position: static;
    opacity: 1;
  }
}
@media screen and (max-width: 575px) {
  .coupon-box {
    max-width: 100%;
    flex-basis: 100%;
  }
  .coupon-left {
    min-height: 85px;
  }
  .coupon-left img {
    max-height: 60px;
  }
  .coupon-right {
    min-height: 85px;
  }
  .coupon-right img {
    max-height: 40px;
  }
}