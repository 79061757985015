@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.container {
  max-width: 1370px;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto;
}
.container, .container-fluid {
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  flex-direction: row;
  margin-left: -15px;
  margin-right: -15px;
}

[class^=col-] {
  width: 100%;
  padding: 0 15px;
}

.col {
  flex-grow: 1;
}
.col-right {
  text-align: right;
}
.col-center {
  text-align: center;
}
.col-content-center {
  margin: 0 auto;
}
.col-2 {
  max-width: 16.66667%;
  flex-basis: 16.66667%;
}
.col-3 {
  max-width: 25%;
  flex-basis: 25%;
}
.col-4 {
  max-width: 33.33333%;
  flex-basis: 33.33333%;
}
.col-5 {
  max-width: 41.6666666667%;
  flex-basis: 41.6666666667%;
}
.col-6 {
  max-width: 50%;
  flex-basis: 50%;
}
.col-7 {
  max-width: 58.33333333%;
  flex-basis: 58.33333333%;
}
.col-8 {
  max-width: 66.66667%;
  flex-basis: 66.66667%;
}
.col-9 {
  max-width: 73.33333333%;
  flex-basis: 73.33333333%;
}
.col-10 {
  max-width: 83.33333%;
  flex-basis: 83.33333%;
}
.col-12 {
  max-width: 100%;
  flex-basis: 100%;
}

.column-3 {
  column-count: 3;
}

@media screen and (max-width: 1440px) {
  .container {
    max-width: 1170px;
  }
}
@media screen and (max-width: 1199px) {
  .container {
    max-width: 960px;
  }
  .col-lg-4 {
    max-width: 33.33333333%;
    flex-basis: 33.33333333%;
  }
  .col-lg-6 {
    max-width: 50%;
    flex-basis: 50%;
  }
  .col-lg-7 {
    max-width: 58.33333333%;
    flex-basis: 58.33333333%;
  }
  .col-lg-12 {
    max-width: 100%;
    flex-basis: 100%;
  }
}
@media screen and (max-width: 991px) {
  .col-md-3 {
    max-width: 25%;
    flex-basis: 25%;
  }
  .col-md-6 {
    max-width: 50%;
    flex-basis: 50%;
  }
  .col-md-12 {
    max-width: 100%;
    flex-basis: 100%;
  }
  .container {
    max-width: 720px;
  }
}
@media screen and (max-width: 767px) {
  .col-sm-6 {
    max-width: 50%;
    flex-basis: 50%;
  }
  .col-sm-12 {
    max-width: 100%;
    flex-basis: 100%;
  }
  .container {
    max-width: 540px;
  }
}
@media screen and (max-width: 574px) {
  .col-xs-12 {
    max-width: 100%;
    flex-basis: 100%;
  }
  .container {
    max-width: unset;
  }
}