@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.tc-left img.img-resp {
  display: inherit;
}
.tc-right .inner {
  position: relative;
  background-color: #EEF1F2;
  padding: 35px 10px 40px;
}
.tc-right .inner.has-buttons {
  padding: 35px 10px 90px;
}
.tc-logo h1 {
  margin: 0;
}
.tc-logo h1 img {
  max-width: 100%;
  height: auto;
}
.tc-title {
  position: relative;
}
.tc-title:before {
  content: "";
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  height: 1px;
  width: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQI12OYNGV6AwAFtwI+CljclAAAAABJRU5ErkJggg==);
}
.tc-title span {
  position: relative;
  display: table;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  color: #1D1D1B;
  background-color: #EEF1F2;
  padding: 15px 20px;
  margin: 0 auto;
  z-index: 1;
}
.tc-opening, .tc-contact {
  text-align: center;
}
.tc-opening p, .tc-opening a, .tc-contact p, .tc-contact a {
  color: #373A3C;
}
.tc-opening a, .tc-contact a {
  display: block;
  word-break: break-all;
}
.tc-opening a:hover, .tc-contact a:hover {
  color: #373A3C;
}
.tc-contact {
  display: table;
  padding: 0 5px;
  margin-right: auto;
  margin-left: auto;
}
.tc-contact p, .tc-contact a {
  line-height: 1.1;
}
.tc-contact p {
  margin-bottom: 16px;
}
.tc-contact .d-flex {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.tc-contact .d-flex img {
  margin-right: 5px;
}
.tc-contact .d-flex p {
  margin-bottom: 0;
}
.tc-buttons {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.tc-buttons .mom-card {
  display: block;
  font-size: 14px;
  color: #FFFFFF;
  text-transform: uppercase;
  text-align: center;
  background-color: #D88373;
  padding: 12px 10px;
}
.tc-buttons .mom-card .shop-extra {
  background-color: #FFFFFF;
}
.tc-buttons .mom-card .shop-extra, .tc-buttons .mom-card .shop-extra span {
  color: #D88373;
}
.tc-buttons .animalfriend {
  display: table;
  margin: 5px auto;
}
.tc-buttons-relative {
  position: relative;
  display: flex;
  justify-content: space-around;
}
.tc-buttons-relative .store-icon {
  margin-top: 20px;
}
.tc-buttons-relative .store-icon img {
  display: inherit;
}
.tc-slider, .tc-slider .slick-list, .tc-slider .slick-track, .tc-slider-item {
  height: 100% !important;
}
.tc-slider .slick-slide {
  border: none;
}
.tc-slider-item {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}