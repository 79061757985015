@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.page__parking {
  padding-bottom: 26px;
}
.page__parking .pm__right .pm__item {
  margin-bottom: 35px;
}
.page__parking ul {
  list-style-position: inside;
  list-style-type: disc;
}
.page__parking ul li::marker {
  color: #c9baa1;
}