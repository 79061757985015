@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.page-shoppingbag .sb-img-top {
  padding-right: 0;
}
.page-shoppingbag .sb-img-top img {
  display: inherit;
}
.page-shoppingbag .sb-img-bottom {
  padding-left: 0;
}
.page-shoppingbag .sb-img-bottom img {
  display: inherit;
}
.page-shoppingbag .sb-box-top {
  padding-left: 0;
}
.page-shoppingbag .sb-box-bottom {
  padding-right: 0;
}
.page-shoppingbag .sb-box-inner {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #ea7a46;
  height: 100%;
}
.page-shoppingbag .sb-box-content {
  text-align: center;
}
.page-shoppingbag .sb-box-content h1 {
  max-width: 100%;
  flex-basis: 100%;
  font-weight: 600;
  color: #FFFFFF;
  margin-bottom: 0;
}
.page-shoppingbag .sb-box-content p {
  font-size: 30px;
  color: #FFFFFF;
  line-height: 1.3;
}
.page-shoppingbag .sb-content {
  text-align: center;
  background-image: url("../img/pages/page-shoppingbag-bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  padding-top: 59px;
  padding-bottom: 97px;
}
.page-shoppingbag .sb-content h2 {
  display: inline-block;
  font-size: 30px;
  color: #ea7a46;
  text-transform: uppercase;
  line-height: 1.4;
  border-bottom: 1px solid #ea7a46;
  padding-bottom: 5px;
  margin-bottom: 35px;
}
.page-shoppingbag .sb-content p, .page-shoppingbag .sb-content a {
  font-size: 20px;
  line-height: 1.3;
}
.page-shoppingbag .sb-content p {
  margin-bottom: 25px;
}

@media screen and (max-width: 1199px) {
  .page-shoppingbag .sb-box-content img {
    width: 180px;
    height: auto;
  }
  .page-shoppingbag .sb-box-content h1 {
    font-size: 40px;
  }
  .page-shoppingbag .sb-box-content p {
    font-size: 25px;
    line-height: 1.2;
  }
  .page-shoppingbag .sb-content {
    background-size: contain;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .page-shoppingbag .sb-content h2 {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 991px) {
  .page-shoppingbag .sb-box-content h1 {
    font-size: 35px;
  }
  .page-shoppingbag .sb-box-content p {
    font-size: 20px;
  }
  .page-shoppingbag .sb-content {
    padding: 30px 80px;
  }
  .page-shoppingbag .sb-content p br {
    display: none;
  }
  .page-shoppingbag .sb-content p, .page-shoppingbag .sb-content a {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .page-shoppingbag .col-6 {
    max-width: 100%;
    flex-basis: 100%;
  }
  .page-shoppingbag .sb-img-top {
    padding-right: 5px;
  }
  .page-shoppingbag .sb-img-bottom {
    padding-left: 5px;
  }
  .page-shoppingbag .sb-box-top {
    padding-left: 5px;
  }
  .page-shoppingbag .sb-box-bottom {
    padding-right: 5px;
  }
  .page-shoppingbag .sb-box-bottom .sb-box-content p {
    font-size: 25px;
  }
  .page-shoppingbag .sb-box-content {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .page-shoppingbag .sb-box-content img {
    width: 140px;
    height: auto;
  }
  .page-shoppingbag .sb-box-content h1 {
    font-size: 35px;
  }
  .page-shoppingbag .sb-box-content p {
    font-size: 20px;
  }
  .page-shoppingbag .sb-content {
    padding: 30px 40px;
  }
  .page-shoppingbag .sb-content h2 {
    font-size: 25px;
    line-height: 1.2;
  }
  .page-shoppingbag .sb-content p, .page-shoppingbag .sb-content a {
    font-size: 16px;
  }
}