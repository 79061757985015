@charset "UTF-8";
@font-face {
  font-family: "mompark";
  src: url("../fonts/2024/mompark.eot");
  src: url("../fonts/2024/mompark.eot#iefix") format("embedded-opentype"), url("../fonts/2024/mompark.woff2") format("woff2"), url("../fonts/2024/mompark.woff") format("woff"), url("../fonts/2024/mompark.ttf") format("truetype"), url("../fonts/2024/mompark.svg#mompark") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=wi-]:before, [class*=" wi-"]:before {
  font-family: "mompark";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*.wi-allatbarat:before { content: '\e800'; } !* '' *!
.wi-jatszohaz:before { content: '\e802'; } !* '' *!
.wi-informacio:before { content: '\e803'; } !* '' *!
.wi-parkolas:before { content: '\e804'; } !* '' *!
.wi-lakat:before { content: '\e805'; } !* '' *!
.wi-kartya:before { content: '\e806'; } !* '' *!
.wi-toltoallomas:before { content: '\e808'; } !* '' *!
.wi-level:before { content: '\e809'; } !* '' *!
.wi-logo:before { content: '\e80a'; } !* '' *!
.wi-beallitasok:before { content: '\e80b'; } !* '' *!
.wi-pin-ures:before { content: '\e80d'; } !* '' *!
.wi-kereses:before { content: '\e80f'; } !* '' *!
.wi-nyitas-teli:before { content: '\e810'; } !* '' *!
.wi-facebook:before { content: '\e811'; } !* '' *!
.wi-nyelv:before { content: '\e812'; } !* '' *!
.wi-pin-teli:before { content: '\e813'; } !* '' *!
.wi-pelenkazo:before { content: '\e815'; } !* '' *!
.wi-nyitas-ures:before { content: '\e816'; } !* '' *!
.wi-instagram:before { content: '\e817'; } !* '' *!
.wi-telefon:before { content: '\e818'; } !* '' *!
.wi-wifi:before { content: '\e819'; } !* '' *!
.wi-group-23:before { content: '\e81a'; } !* '' *!
.wi-baba-mama:before { content: '\e81b'; } !* '' *!*/
/*.wi-allatbarat:before { content: '\e800'; } !* '' *!
.wi-kupon:before { content: '\e801'; } !* '' *!
.wi-jatszohaz:before { content: '\e802'; } !* '' *!
.wi-informacio:before { content: '\e803'; } !* '' *!
.wi-parkolas:before { content: '\e804'; } !* '' *!
.wi-lakat:before { content: '\e805'; } !* '' *!
.wi-kartya:before { content: '\e806'; } !* '' *!
.wi-taska:before { content: '\e807'; } !* '' *!
.wi-toltoallomas:before { content: '\e808'; } !* '' *!
.wi-level:before { content: '\e809'; } !* '' *!
.wi-logo:before { content: '\e80a'; } !* '' *!
.wi-beallitasok:before { content: '\e80b'; } !* '' *!
.wi-link:before { content: '\e80c'; } !* '' *!
.wi-pin-ures:before { content: '\e80d'; } !* '' *!
.wi-kereses:before { content: '\e80f'; } !* '' *!
.wi-nyitas-teli:before { content: '\e810'; } !* '' *!
.wi-facebook:before { content: '\e811'; } !* '' *!
.wi-nyelv:before { content: '\e812'; } !* '' *!
.wi-pin-teli:before { content: '\e813'; } !* '' *!
.wi-pelenkazo:before { content: '\e815'; } !* '' *!
.wi-nyitas-ures:before { content: '\e816'; } !* '' *!
.wi-instagram:before { content: '\e817'; } !* '' *!
.wi-telefon:before { content: '\e818'; } !* '' *!
.wi-wifi:before { content: '\e819'; } !* '' *!
.wi-group-23:before { content: '\e81a'; } !* '' *!
.wi-baba-mama:before { content: '\e81b'; } !* '' *!*/
.wi-allatbarat:before {
  content: "\e800";
} /* '' */
.wi-kupon:before {
  content: "\e801";
} /* '' */
.wi-jatszohaz:before {
  content: "\e802";
} /* '' */
.wi-informacio:before {
  content: "\e803";
} /* '' */
.wi-parkolas:before {
  content: "\e804";
} /* '' */
.wi-lakat:before {
  content: "\e805";
} /* '' */
.wi-kartya:before {
  content: "\e806";
} /* '' */
.wi-taska:before {
  content: "\e807";
} /* '' */
.wi-toltoallomas:before {
  content: "\e808";
} /* '' */
.wi-level:before {
  content: "\e809";
} /* '' */
.wi-logo:before {
  content: "\e80a";
} /* '' */
.wi-beallitasok:before {
  content: "\e80b";
} /* '' */
.wi-link:before {
  content: "\e80c";
} /* '' */
.wi-pin-ures:before {
  content: "\e80d";
} /* '' */
.wi-user:before {
  content: "\e80e";
} /* '' */
.wi-kereses:before {
  content: "\e80f";
} /* '' */
.wi-nyitas-teli:before {
  content: "\e810";
} /* '' */
.wi-facebook:before {
  content: "\e811";
} /* '' */
.wi-nyelv:before {
  content: "\e812";
} /* '' */
.wi-pin-teli:before {
  content: "\e813";
} /* '' */
.wi-dokumentum:before {
  content: "\e814";
} /* '' */
.wi-pelenkazo:before {
  content: "\e815";
} /* '' */
.wi-nyitas-ures:before {
  content: "\e816";
} /* '' */
.wi-instagram:before {
  content: "\e817";
} /* '' */
.wi-telefon:before {
  content: "\e818";
} /* '' */
.wi-wifi:before {
  content: "\e819";
} /* '' */
.wi-group-23:before {
  content: "\e81a";
} /* '' */
.wi-baba-mama:before {
  content: "\e81b";
} /* '' */
.wi-modositas:before {
  content: "\e81e";
} /* '' */
.wi-kilepes:before {
  content: "\e821";
} /* '' */
.wi-upload:before {
  content: "\e822";
} /* '' */
.wi-kartya-1:before {
  content: "\e823";
} /* '' */
.wi-bezaras:before {
  content: "\e824";
} /* '' */
.wi-plusz-kor:before {
  content: "\e825";
} /* '' */
.wi-nyil-le:before {
  content: "\e826";
} /* '' */
.wi-minusz-kor:before {
  content: "\e827";
} /* '' */