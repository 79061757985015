@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url("../img/empty.svg");
  background-position: center center;
  background-repeat: repeat;
  background-size: auto;
  z-index: 1000;
  -ms-transform: translateY(1000%);
  -webkit-transform: translateY(1000%);
  -moz-transform: translateY(1000%);
  -o-transform: translateY(1000%);
  transform: translateY(1000%);
  -moz-transition: transform 300ms ease-in-out;
  -o-transition: transform 300ms ease-in-out;
  -webkit-transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
}
.popup-title {
  position: relative;
  font-size: 25px;
  font-weight: 800;
  color: #e6dfd4;
  line-height: 1.1944444444;
  text-align: center;
  text-transform: uppercase;
  background-color: #c9baa1;
  padding: 15px 15px;
}
.popup-footer {
  background-color: #e6dfd4;
  border-top: 1px solid #373A3C;
  text-align: right;
  padding: 16px 15px;
  margin-top: -1px;
}
.popup-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 640px;
  width: calc(100% - 30px);
  z-index: 1;
}
.popup-content {
  width: 100%;
  max-height: calc(100vh - 102px);
  text-align: center;
  background-color: #e6dfd4;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px 40px 40px;
}
.popup-content .h4 {
  font-weight: 600;
}
.popup-content .form-control {
  background-color: #EEF1F2;
}
.popup-content .form-submit {
  max-width: unset;
}
.popup-content ul {
  display: table;
  text-align: left;
  list-style-position: inside;
  list-style-type: disc;
  margin-right: auto;
  margin-left: auto;
}
.popup-content ul li {
  font-size: 18px;
}
.popup-content ul li::marker {
  color: #c9baa1;
}
.popup-close {
  cursor: pointer;
  position: absolute;
  top: 9px;
  right: 12px;
  font-size: 40px;
  color: #e6dfd4;
  line-height: 1;
  z-index: 2;
}
.popup-link {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 800px;
  width: 100%;
  height: 81px;
  z-index: 2;
}
.popup-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.popup img {
  display: block;
  width: auto;
  height: 100%;
  margin: 0 auto;
}
.popup a {
  display: block;
  width: auto;
  height: calc(100vh - 30px);
}
.popup.active {
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
.popup-advert .popup-inner {
  max-width: unset;
  width: auto;
}
.popup-apcode .popup-inner {
  max-height: unset;
  height: unset;
  background-color: #FFFFFF;
}
.popup-apcode .popup-inner p {
  margin-bottom: 16px;
}
.popup-apcode .popup-img {
  max-width: 350px;
  width: 100%;
  height: auto;
}
.popup-errors h2 {
  margin-bottom: 10px;
}
.popup-errors ul {
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 20px;
}
.popup-errors ul li {
  margin-bottom: 2px;
}