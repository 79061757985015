@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.page__shop {
  padding-bottom: 22px;
}

.pshop__left {
  max-width: calc(66.66667% - 15px);
  flex-basis: calc(66.66667% - 15px);
  padding-left: 15px;
}
.pshop__left-map {
  position: relative;
  display: flex;
  align-items: center;
  height: unset;
  padding: 0 15px;
}
.pshop__left-map .slick-dots li:not(:last-child) button {
  margin-bottom: 18px;
}
.pshop__left-map .slick-dots li button {
  cursor: default;
  width: 31px;
  height: 31px;
  font-size: 15px;
  color: #C9BBA1;
  text-indent: 0;
  border: 2px solid #C9BBA1;
}
.pshop__left-map .slick-dots li button:before {
  display: none;
}
.pshop__left-map .slick-dots li.slick-active button {
  color: #1D1D1B;
  border-color: #1D1D1B;
}
.pshop__left .slick-dots {
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  background-clip: padding-box;
  padding: 15px 3px;
}
.pshop__left .slick-dots button:before {
  color: rgba(0, 0, 0, 0.5);
}
.pshop__left .slick-active button {
  border-color: rgba(0, 0, 0, 0.5);
}
.pshop__left .floor-container g:not(.selected):hover {
  fill: #c9baa1 !important;
}
.pshop__right {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  max-width: calc(33.33333% + 15px);
  flex-basis: calc(33.33333% + 15px);
  padding-right: 15px;
}
.pshop__right:not(.pshop__right-info) {
  padding: 30px 15px;
}
.pshop__right–info-full {
  max-width: 100%;
  flex-basis: 100%;
  margin-left: 15px;
  margin-bottom: 30px;
}
.pshop__inner h2 {
  font-size: 20px;
  line-height: 1.2;
  color: #c9baa1;
  margin-bottom: -1px;
}
.pshop__inner p {
  line-height: 1.3;
}
.pshop__inner p:not(:last-child) {
  margin-bottom: 22px;
}
.pshop__inner i {
  color: #c9baa1;
  margin-right: 10px;
}
.pshop__inner i.wi-small {
  font-size: 17px;
}
.pshop__inner i.wi-big {
  font-size: 27px;
}
.pshop__inner-dark {
  background-color: #e6dfd4;
  height: 100%;
}
.pshop__header {
  font-weight: 900;
  color: #e6dfd4;
  text-align: center;
  background-color: #c9baa1;
  padding: 10px 15px;
}
.pshop__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 51px);
  font-size: 18px;
  padding: 10px 37px;
}
.pshop__content p:not(:last-child) {
  margin-bottom: 20px;
}
.pshop__logo {
  display: table;
  width: auto;
  max-height: 80px;
  margin-right: auto;
  margin-bottom: 24px;
  margin-left: auto;
}
.pshop__item {
  margin-bottom: 22px;
}
.pshop__item strong {
  display: block;
  font-weight: 600;
  margin-bottom: -2px;
}
.pshop__item-social p, .pshop__item-extra p {
  margin-bottom: -4px !important;
}
.pshop__item-social p:not(:nth-child(2)), .pshop__item-extra p:not(:nth-child(2)) {
  margin-bottom: 4px !important;
}
.pshop__item-social p a, .pshop__item-extra p a {
  word-break: break-all;
  hyphens: auto;
}
.pshop__item-extra {
  margin-bottom: 0 !important;
}

.tc-image-one {
  height: 100%;
  object-fit: cover;
}
.tc-shoppage {
  margin-bottom: 40px;
}
.tc-desc {
  display: flex;
  flex-direction: column;
}
.tc-desc .inner {
  position: relative;
  flex-grow: 1;
  font-size: 16px;
  line-height: 1.5;
  background-color: #EEF1F2;
  padding: 20px;
}
.tc-desc .inner p {
  margin-bottom: 16px;
}
.tc-desc .inner p:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 991px) {
  .tc-image-one {
    width: 100%;
    height: auto;
    object-fit: unset;
  }
}
@media screen and (max-width: 1440px) {
  .pshop__left-map, .pshop__right-info {
    max-width: 100%;
    flex-basis: 100%;
  }
  .pshop__left-map {
    order: 4;
  }
  .pshop__right-info {
    order: 3;
    padding-left: 15px;
  }
  .pshop__inner {
    width: 100%;
  }
}
@media screen and (max-width: 1199px) {
  .pshop__left {
    height: 500px;
    padding-right: 15px;
  }
  .pshop__left, .pshop__right {
    max-width: 100%;
    flex-basis: 100%;
  }
}
@media screen and (max-width: 991px) {
  .pshop__left {
    height: 400px;
  }
  .pshop__left svg {
    width: auto;
    height: 400px;
  }
}
@media screen and (max-width: 768px) {
  .pshop__left-map {
    order: 3;
  }
  .pshop__right-info {
    order: 4;
  }
  .pshop__left svg {
    margin-top: 40px;
  }
  .pshop__left .slick-dots {
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    transform: none;
    padding-top: 0;
    padding-bottom: 0;
  }
  .pshop__left .slick-dots li {
    margin-right: 10px;
    margin-left: 10px;
  }
  .pshop__left .slick-dots li:not(:last-child) button {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 575px) {
  .pshop__left {
    height: 350px;
  }
  .pshop__left svg {
    width: auto;
    height: 350px;
    margin-left: auto;
  }
}