@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
html.page-loaded .tr {
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
html.page-loaded .tr-600 {
  -moz-transition: all 600ms ease-in-out;
  -o-transition: all 600ms ease-in-out;
  -webkit-transition: all 600ms ease-in-out;
  transition: all 600ms ease-in-out;
}