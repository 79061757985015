@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
[class^=icon-], [class*=" icon-"] {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-kartya:before {
  content: "\e906";
}

.icon-menu_icon_earth:before {
  content: "\e900";
}

.icon-menu_icon_face:before {
  content: "\e901";
}

.icon-menu_icon_insta:before {
  content: "\e902";
}

.icon-menu_icon_message:before {
  content: "\e903";
}

.icon-menu_icon_search:before {
  content: "\e904";
}

.icon-menu_icon_youtube:before {
  content: "\e905";
}

.icon-menu_icon_card:before {
  content: "\e906";
}

.icon-menu_icon_animalfriend {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNDAuMTYgMzQwLjE2Ij48ZGVmcz48c3R5bGU+LmNscy0xLC5jbHMtNCwuY2xzLTV7ZmlsbDpub25lO30uY2xzLTJ7ZmlsbDojMjIyMjIxO30uY2xzLTN7ZmlsbDojZmZmO30uY2xzLTR7c3Ryb2tlOiMyMjIyMjE7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS13aWR0aDozLjY4cHg7fS5jbHMtNXtzdHJva2U6IzI3MjQyNTtzdHJva2UtbWl0ZXJsaW1pdDoxMDtzdHJva2Utd2lkdGg6My43NXB4O308L3N0eWxlPjwvZGVmcz48dGl0bGU+TU9NX0FsbGF0YmFyYXRfbWF0aWNhX2NpcmNsZV8xMjB4MTIwPC90aXRsZT48Y2lyY2xlIGNsYXNzPSJjbHMtMSIgY3g9IjE3MC4wOCIgY3k9IjE3MC4wOCIgcj0iMTcwLjA4Ii8+PGNpcmNsZSBjbGFzcz0iY2xzLTIiIGN4PSIxMjkuMDYiIGN5PSIxNjUuNCIgcj0iMTEuOTciLz48Y2lyY2xlIGNsYXNzPSJjbHMtMyIgY3g9IjEzMi42MyIgY3k9IjE2MS4yNiIgcj0iNC4zIi8+PGNpcmNsZSBjbGFzcz0iY2xzLTIiIGN4PSIyMTAuMDYiIGN5PSIxNjUuNCIgcj0iMTEuOTciLz48Y2lyY2xlIGNsYXNzPSJjbHMtMyIgY3g9IjIxMy42MyIgY3k9IjE2MS4yNiIgcj0iNC4zIi8+PHBhdGggY2xhc3M9ImNscy00IiBkPSJNMTAyLjY5LDEyMi40MWw2LjA3LTMzLjI5YTMuMjcsMy4yNywwLDAsMSw1Ljc1LTEuNDlMMTM2LDExMy44MiIvPjxwYXRoIGNsYXNzPSJjbHMtNCIgZD0iTTIwMi43LDExMy42Mmw3Ljc1LTEyLjksMzMuODYuOWEzLjI3LDMuMjcsMCwwLDEsMi4zNiw1LjQ1TDIzMCwxMzEuODdsLTE2LjUtMjMuMjUiLz48cGF0aCBjbGFzcz0iY2xzLTIiIGQ9Ik0xODguNTcsMjIxLjkxbC0xMy40MSwxMy40MWE3Ljk0LDcuOTQsMCwwLDEtMTEuMjEsMGwtMTMuNC0xMy40MWE3LjkyLDcuOTIsMCwwLDEsNS42LTEzLjUzSDE4M0E3LjkzLDcuOTMsMCwwLDEsMTg4LjU3LDIyMS45MVoiLz48cGF0aCBjbGFzcz0iY2xzLTQiIGQ9Ik0xNjkuNTYsMjMxLjU2VjI0NC45YTEyLjg0LDEyLjg0LDAsMCwxLTEyLjgsMTIuOGgwQTEyLjg0LDEyLjg0LDAsMCwxLDE0NCwyNDQuOSIvPjxwYXRoIGNsYXNzPSJjbHMtNCIgZD0iTTE2OS41NiwyMzEuNTZWMjQ0LjlhMTIuODQsMTIuODQsMCwwLDAsMTIuOCwxMi44aDBhMTIuODQsMTIuODQsMCwwLDAsMTIuNzktMTIuOCIvPjxjaXJjbGUgY2xhc3M9ImNscy01IiBjeD0iMTcwLjA4IiBjeT0iMTcwLjA4IiByPSIxMzguOCIvPjwvc3ZnPg==");
}