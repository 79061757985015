@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.news-page-cinema .news-bottom {
  justify-content: center;
  margin-top: 30px;
}
.news-page-cinema .news-bottom .btn {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  max-width: 517px;
  width: 100%;
}
.news-page-cinema .news-bottom .btn i {
  font-size: 27px;
  margin-right: 10px;
}
.news-page-cinema .news-bottom .btn i:before {
  line-height: 1;
}