@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.magazine {
  padding-bottom: 40px;
}
.magazine__hero img {
  margin-bottom: 30px;
}
.magazine__hero h1 {
  margin-bottom: 34px;
}
.magazine__hero p {
  margin-bottom: 30px;
}
.magazine__hero p a {
  color: #c9baa1;
  text-decoration: underline;
}
.magazine-item a {
  position: relative;
  display: block;
  overflow: hidden;
}
.magazine-item a img {
  display: table;
  margin-right: auto;
  margin-bottom: 15px;
  margin-left: auto;
}
.magazine-item a:hover:after {
  right: 0;
  opacity: 1;
}
.magazine-date {
  font-weight: 600;
  color: #c9baa1;
  text-transform: uppercase;
  text-align: center;
}
.magazine__arrow {
  max-width: 75px;
  flex-basis: 75px;
  padding-top: 105px;
}
.magazine__arrow button {
  cursor: pointer;
  font-size: 40px;
  color: #c9baa1;
}
.magazine__arrow-prev {
  transform: rotate(90deg);
}
.magazine__arrow-next {
  transform: rotate(-90deg);
}
.magazine__arrow-right {
  text-align: right;
}
.magazine__slider {
  max-width: calc(100% - 150px);
  flex-basis: calc(100% - 150px);
}
.magazine__slider .slick-arrow {
  display: none !important;
  font-size: 0;
  text-indent: -9999px;
}

@media screen and (max-width: 1440px) {
  .magazine-item {
    margin: 0 10px;
  }
  .magazine__arrow {
    max-width: 50px;
    flex-basis: 50px;
    padding-top: 88px;
  }
  .magazine__slider {
    max-width: calc(100% - 100px);
    flex-basis: calc(100% - 100px);
  }
}
@media screen and (max-width: 1199px) {
  .magazine__arrow {
    padding-top: 63px;
  }
}
@media screen and (max-width: 1199px) {
  .magazine__arrow {
    padding-top: 73px;
  }
}
@media screen and (max-width: 768px) {
  .magazine .row {
    position: relative;
  }
  .magazine-date {
    padding: 0 35px;
  }
  .magazine__arrow {
    position: absolute;
    bottom: -4px;
    padding-top: 0;
    z-index: 1;
  }
  .magazine__arrow button {
    font-size: 0;
    line-height: 1;
    background-color: #FFFFFF;
    border-radius: 50%;
  }
  .magazine__arrow i {
    font-size: 30px;
  }
  .magazine__arrow i:before {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-clip: padding-box;
  }
  .magazine__arrow-left {
    left: 15px;
  }
  .magazine__arrow-right {
    right: 15px;
  }
  .magazine__slider {
    position: relative;
    max-width: 100%;
    flex-basis: 100%;
  }
  .magazine-item {
    padding: 0 15px;
    margin: 0;
  }
  .magazine-item img {
    width: 100%;
  }
}