@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.page__openinghours h2, .page__openinghours h3 {
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  margin-bottom: 4px;
}
.page__openinghours .small {
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.1666666667 !important;
}

.po__hero {
  margin-bottom: 13px;
}
.po__inner {
  color: #1D1D1B;
  background-color: #e6dfd4;
  padding: 46px 35px 18px;
}
.po__inner p {
  line-height: 1.6;
  margin-bottom: 17px;
}
.po__inner p strong {
  display: block;
  font-weight: 600;
  margin-bottom: -8px;
}
.po__list {
  column-count: 4;
  column-gap: 30px;
  text-align: center;
}
.po__closinghours.col-12 {
  padding-top: 45px;
  padding-bottom: 17px;
}
.po__closinghours h3 {
  color: #c9baa1;
  margin-bottom: 22px;
}
.po__closinghours ul {
  margin-bottom: 28px;
}
.po__closinghours .small {
  line-height: 1.1666666667;
  margin-bottom: 30px;
}

@media screen and (max-width: 991px) {
  .po__inner {
    padding: 20px 15px;
  }
  .po__inner p {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 1440px) {
  .po__list {
    column-count: 3;
    column-gap: 20px;
  }
}
@media screen and (max-width: 991px) {
  .po__list {
    column-count: 2;
    column-gap: 15px;
  }
}
@media screen and (max-width: 768px) {
  .page__openinghours h2, .page__openinghours h3 {
    font-size: 18px;
  }
}
@media screen and (max-width: 575px) {
  .po__list {
    column-count: unset;
    column-gap: unset;
  }
}