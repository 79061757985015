@charset "UTF-8";
/* Színek 2024 */
/* MOM Park Kártya */
.ps__hero {
  margin-bottom: 16px;
}
.ps__box {
  margin-bottom: 30px;
}
.ps__inner {
  height: 100%;
  border: 1.5px solid #c9baa1;
  padding: 17px 10px 30px;
}
.ps__inner i {
  display: block;
  font-size: 67px;
  color: #c9baa1;
  margin-bottom: 9px;
}
.ps__inner i:before {
  line-height: 1;
}
.ps__inner i.wi-nyitas-teli {
  font-size: 40px;
  margin-bottom: 0;
}
.ps__inner i.wi-nyitas-teli:before {
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.ps__inner h2 {
  font-size: 20px;
  font-weight: 900;
  color: #c9baa1;
  margin-bottom: 24px;
}
.ps__inner p {
  line-height: 1.2;
}
.ps__inner p:not(:last-child) {
  margin-bottom: 25px;
}

@media screen and (max-width: 1440px) {
  .ps__inner br {
    display: none;
  }
}
@media screen and (max-width: 1199px) {
  .ps__inner i {
    font-size: 50px;
  }
}
@media screen and (max-width: 768px) {
  .ps__inner {
    padding: 15px;
  }
  .ps__inner i {
    font-size: 40px;
    margin-bottom: 25px;
  }
  .ps__inner i.wi-nyitas-teli {
    font-size: 30px;
  }
  .ps__inner h2 {
    margin-bottom: 20px;
  }
  .ps__inner p:not(:last-child) {
    margin-bottom: 20px;
  }
}